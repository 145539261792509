import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../actions/user";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Dialog from '@mui/material/Dialog';
import { Navigate, useParams, useNavigate, Link } from 'react-router-dom';
import ReceiptLongSharpIcon from '@mui/icons-material/ReceiptLongSharp';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import SearchIcon from '@mui/icons-material/Search';

const BoardAdmin = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState([]);
  const { userData } = useSelector(state => state.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [viewType, setViewType] = useState('list');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const usersPerPage = 6;

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = userData.slice(indexOfFirstUser, indexOfLastUser);

  const paginateGrid = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginateList = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleView = () => {
    setViewType((prevType) => (prevType === 'grid' ? 'list' : 'grid'));
  };
 
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); 
  };

  useEffect(() => {
    if (!userData || !userData.length) {
      UserService.getAdminBoard().then(
        (response) => {
          //setContent(response.data);
          //setContent(response.data);
          dispatch(setUserData(response.data));
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setContent(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  }, []);

  console.log(userData);
  const renderUsers = (filteredUsers) => {
    return filteredUsers.map(u => (
      <div key={u.id} className="col-sm-12 col-md-6 col-lg-4 my-2" style={{ width: "18rem", display: 'inline-block' }}>
        <div className="card">
          <img className="useerCard-img-top" style={{ padding: '40px' }} src={'/img/user.png'} alt="" />
          <div className="card-body">
            <h5 className="card-title">{u.username}</h5>
            <label className="card-text">{u.email}</label>
            {u.roles.length === 0 ? <span className={'lbl-category role-bg-4'}  >CAMPAIGNER</span> : u.roles.map(r => <span className={'lbl-category role-bg-' + r.id}>{r.name.toUpperCase()}</span>)

            }
            <p className="card-text">{'Joined: ' + u.createdAt.split('T')[0]}</p>

            <Link className="btn btn-info text-black" style={{float: "left"}} to={`/billing?id=${u.id}`}><ReceiptIcon /></Link>

            <Dialog
              open={open === u.id}
              fullWidth={true}
            >
              <h1>Listing soon........</h1>
              <div>
                <button style={{ float: 'right' }} onClick={() => setOpen(!open)} className='btn btn-danger m-2'>close</button>
              </div>
            </Dialog>


            <button onClick={() => setOpen(u.id)} style={{ float: 'right' }} className="btn btn-primary"><RemoveRedEye /></button>
          </div>
        </div>
      </div>
    ));

  };
  const renderView = () => {
    const usersToRender = viewType === 'grid' ? currentUsers : userData.slice(indexOfFirstUser, indexOfLastUser);
    const filteredUsers = usersToRender.filter(u =>
      u.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      u.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return viewType === 'grid' ? renderUsers(filteredUsers) : tableView(filteredUsers);
  };
  

  const tableView = (usersToRender) => {
    const filteredUsers = usersToRender.filter(u =>
      u.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      u.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
      <div>
        <table cellPadding='5px' cellSpacing='5px' style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr align='center'>
              <th>Profile</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>User role</th>
              <th>Joined</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {usersToRender.map(u => (
              <tr key={u.id}>
                <td style={{ borderRight: '1px solid #ddd' }}><img height='120px' width='120px' style={{ padding: '10px' }} src={'/img/user.png'} alt="" /></td>
                <td style={{ borderRight: '1px solid #ddd' }}>{u.username}</td>
                <td style={{ borderRight: '1px solid #ddd' }}>{u.email}</td>
                <td style={{ borderRight: '1px solid #ddd' }}>{u.approvalStatus ? 'Yes' : 'No'}</td>
                <td style={{ borderRight: '1px solid #ddd' }}>
                  {u.roles.length === 0 ? <span className={'lbl-category role-bg-4'} >CAMPAIGNER</span>
                    : u.roles.map(r => <span key={r.id} className={'lbl-category role-bg-' + r.id}>{r.name.toUpperCase()}</span>)
                  }
                </td>
                <td style={{ borderRight: '1px solid #ddd' }}>{u.createdAt.split('T')[0]}</td>
                <td>
                  <Link className="btn btn-info text-black" to={`/billing/${u.id}`}><ReceiptIcon /></Link>
                  <button onClick={() => setOpen(u.id)} className="btn btn-primary ml-2"><RemoveRedEyeIcon /></button>
                </td>
                <Dialog
                  open={open === u.id}
                  fullWidth={true}
                >
                  <h1>Listing soon........</h1>
                  <div>
                    <button style={{ float: 'right' }} onClick={() => setOpen(!open)} className='btn btn-danger m-2'>close</button>
                  </div>
                </Dialog>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  

  return (
    !currentUser ?
      <Navigate to="/login" />
      :
      <div className="container">
        <header className="jumbotron">
          <h3>
        
          </h3>
        </header>
        <h5 style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong>Users List</strong>
        <div className='searchBar'>
            <SearchIcon />
            <input type="text" placeholder="Name, email" value={searchTerm}
        onChange={handleSearch}  style={{ border: 'none', outline: 'none', backgroundColor: "#fefeff00" }} />
          </div>
          <button className="btn" onClick={toggleView}>
            {viewType === 'list' ? <ViewModuleIcon /> : <TableRowsIcon />}
          </button>
        </h5>
        <div className="detail-sec" style={{ textAlign: 'center' }}>
          {userData && userData.length ? renderView() : <p>No users to display.</p>}

          <Stack spacing={2} style={{ justifyContent: 'center', marginTop: '20px' }}>
            <Pagination
              count={Math.ceil(userData.length / usersPerPage)}
              page={currentPage}
              onChange={viewType === 'grid' ? paginateGrid : paginateList}
            />
          </Stack>
        </div>
      </div>
  );
};

export default BoardAdmin;
