import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setUserData} from "../actions/user";
import EventBus from "../common/EventBus";
import { Navigate,useParams, Link,useNavigate } from 'react-router-dom';
import UserService from "../services/user.service";
import AdLocService from "../services/ad-loc.service";

import Dialog from '@mui/material/Dialog';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';

import {MEDIA_GALLERY} from "../constants";
import { setScreenLocationList } from "../actions/screenAndLoc";

const Ads = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState("");
  const { userData } = useSelector(state => state.user);
  const { locList } = useSelector(state => state.screenLoc);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isOpen,setIsOpen]=useState(false);
  const[stautsBtn,setStautsBtn]=useState(true);
  const[changeText,setChangeText]=useState(true);
  // console.log(filterData);
  console.log(userData);
  console.log(MEDIA_GALLERY);
  console.log(locList);


  useEffect(() => {
      if(!userData || !userData.length){


       (currentUser.role.indexOf('SUPER-ADMIN') != -1? UserService.getAdminBoard():UserService.getUserBoard()).then(

      (response) => {
        console.log(response.data);
        dispatch(setUserData(response.data));
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
      }

      if(!locList || !locList.length){
        UserService.getPublicContent().then(
      (response) => {
        dispatch(setScreenLocationList(response.data));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setContent(_content);
      }
    );
      }

  }, []);
// useEffect(()=>{
//       UserService.getPartnersContent()
//       .then(res=>{
//         res.data&&res.data.map(othr=> setContent((prev)=>[...prev,othr]));
        
//       })
//   },[])
    
console.log(content);

  const updateAdStatus = (adLocs, status) => {
      adLocs.approvalStatus = status=='APPROVED'?true:false;

      AdLocService.updateAdLoc(adLocs).then(
          (response) => {
            console.log(response);
            let btn=response?setStautsBtn(!stautsBtn):"";
             // window.location.reload();
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
 
            setContent(_content); 
            
          }
        );

  }

  const showAdLocList=(adId)=>{
    setIsOpen(adId);
   
   
   
  }

  const renderAdAndLocs = () => {
  //https://digisign24.s3.ap-south-1.amazonaws.com/
  console.log(userData)
    return userData.map(u => (u.medias.map(p => (
      p.ads.map(ad =>
       (
        
          <div key={ad.id} className="col-sm-12 col-md-6 col-lg-4 my-3" style={{width: "18rem",display:'inline-block'}}>
          <div className="card" style={{textAlign:"left"}}>
      <img className="userCard-img-top" src={MEDIA_GALLERY+'/'+(p.mediaPath && p.mediaPath.endsWith(".mp4") ? p.mediaPath.replace(".mp4", ".png") : p.mediaPath )} alt="Cardimage cap"/>
      <div className="card-body detail-sec">
      <h5 className="card-title">{p.mediaName}</h5>
      <p className="card-text">{p.mediaTags}</p>
      <label className="card-text">{'Request Date:'}<span className="card-text">{ad.createdAt.split('T')[0]}</span></label>

      
      

      <label className="card-text">{'Scheduled For:'} <span className="card-text">{ad.repeatDuration +' '+ ad.playForDuration}</span></label>
      <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',padding:'2px',flexWrap:"wrap"}}>
         <Link to={"/report?id="+ad.mediaId+"&adName="+p.mediaName+"&thum="+(p.mediaPath && p.mediaPath.endsWith(".mp4") ? p.mediaPath.replace(".mp4", ".png") : p.mediaPath )} className="btn btn-info">Show report</Link>

          <button className="btn btn-primary" onClick={()=>showAdLocList(p.id)}>Apartment List</button>
      </div>
        <Dialog 
        fullWidth={true}
        maxWidth='md'
       
          open={isOpen== p.id}
        >
        
        <div className='container'>
          <table align='center' height='auto' cellPadding='5px' cellSpeacing='3px'>
          <thead>
          <tr>
            <th>Publish At</th>
             <th>Approved Date</th>
            <th>Approval Status</th>
              {currentUser.roles.indexOf('ROLE_ADMIN') != -1 && <th>Action</th>}
          </tr>
          </thead>
            <tbody className='overFlow'>

         {ad.adLocs.map(adloc =>
                  <tr>
                      <td>{locList.length?locList.filter(loc=> loc.id==adloc.playerId)[0].playerName: <Navigate to="/" />}</td>
                      <td align='center'>{adloc.approvalStatus ?<span className="card-text">{adloc.updatedAt.split('T')[0]}</span>:'-'}</td>

                      <td align='center'>{adloc.approvalStatus?<span className="blink_txt text-success highlight_grn">{'RUNNING'}</span>:<span className=" highlight_orng">{'PENDING'}</span>}</td>
                      

                       { currentUser.roles.indexOf('ROLE_ADMIN') != -1 && <td> {adloc.approvalStatus||stautsBtn ?<button onClick={() => updateAdStatus(adloc,"REJECT")}  className="btn btn-danger" style={{marginRight:'10px'}}>Reject</button>
                      :<button onClick={() => updateAdStatus(adloc,"APPROVED")}  className="btn btn-success mt-1">Approve</button>}
                      

                   </td> }
                   </tr>

        // (<div style={{marginBottom:'10px'}}>
        //     <label className="card-text">{'Publish At:'} <span className="card-text">{locList.filter(loc=> loc.id==adloc.playerId)[0].playerName}</span></label>
        //     <label className="card-text">{'Approval Status:'} <span className="card-text">{adloc.approvalStatus?<span className="blink_txt highlight_grn">{'RUNNING'}</span>:<span className=" highlight_orng">{'PENDING'}</span>}</span></label>
        //     <label className="card-text">{'Approved Date:'} {adloc.approvalStatus ?<span className="card-text">{adloc.updatedAt.split('T')[0]}</span>:'-'}</label>

            // {currentUser.roles.indexOf('ROLE_ADMIN') != -1 && <div style={{float:'right',margin:'10px',}}>
            // {adloc.approvalStatus ?<button onClick={() => updateAdStatus(adloc,"REJECT")}  className="btn btn-danger" style={{marginRight:'10px'}}>Reject</button>:<button onClick={() => updateAdStatus(adloc,"APPROVED")}  className="btn btn-success">Approve</button>}</div>}

        //   </div>))

        )}
        </tbody>
        </table>
        </div>

         <div className='btn-close'>
        <button onClick={_=>setIsOpen(false)} className='btn btn-danger fw-bold  close-btn'>Close</button></div>
        </Dialog>



      </div>
      </div>
    </div>
        ))
    ))));
  
};
    const listView=()=>{
     let list= document.getElementById('toggle-btn');
    list.style.display='flex';
   
    }
const gridView=()=>{
  let grid= document.getElementById('toggle-btn');
  grid.style.display='block';
}


  return (
     !currentUser?
    <Navigate to="/login" />
      :
    <div className="container">
      <header className="jumbotron">
        <h3 style={{display:'flex',justifyContent:'space-between' }}>
          <strong>{'Ads'}</strong>
          <div>
        <button className='btn' onClick={gridView}><ViewModuleIcon/> </button>
         <button className='btn' onClick={listView} ><TableRowsIcon/> </button>
        </div>
      </h3>
      </header>
      <h5 ><strong>Your Ads Status:</strong></h5> 
      
      <div className="detail-sec " id='toggle-btn' style={{textAlign:'center'}}>   
        
          {userData?renderAdAndLocs():content}
      </div>
       
      
    </div>

  );

 
};

export default Ads;
