import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../constants";

const SUB_PATH = "/api/digi/media";

const getMediaById = (id) => {
  return axios.get(API_URL+SUB_PATH+ "/"+id);
};

const uploadMedia = (files) => {
	console.log(files)
  return axios
    .post(API_URL + SUB_PATH, files,
    { headers: authHeader(),
     'content-type': 'multipart/form-data' })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
      }

      return response.data;
    });
};


export default {
	getMediaById,
  	uploadMedia
};