import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import UserService from "../services/user.service";
import { LOC_GALLERY_URL } from "../constants";
import PlaceIcon from "@mui/icons-material/Place";
import { Button, Grid, TextField, MenuItem, DialogTitle,DialogContent, Dialog, } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; 
import {
  setScreenLocationSelection,
  setScreenLocationList,
} from "../actions/screenAndLoc";
import ScreenLocService from "../services/screen_location.service";
import * as geolib from "geolib";
import { ReactBingmaps } from 'react-bingmaps';
import {getOptions} from '../util/utils.js'
import Switch from "react-switch";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditPopup from './EditPopUp';
import AddScreen from './AddScreen';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import '../App.css'
// import Autocomplete from '@mui/material/Autocomplete';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const toggleButtonGroupStyle = {
  fontSize: '12px'
};

const toggleButtonStyle = {
  padding: '6px 12px',
  marginBottom: '10px'
};

const Home = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { locList } = useSelector((state) => state.screenLoc, shallowEqual);
  const [totalIP, setTotalIP] = useState(0);
  const [filteredContent, setFilteredContent] = useState([]);
  const [dispaly, setDisplay] = useState(false);
  const [otherData, setOtherData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [otherList, setOtherList] = useState([]);
  const [scrnCount, setScrnCount] = useState(-1);
  const [apartCount, setApartCount] = useState();
  const [ipCount, setIpCount] = useState();
  const [isMapView, setMapView] = useState(false);
  const [SelectedCity, setSelectedCity] = useState('Bangalore');
  const [SelectedRange, setSelectedRange] = useState(2);

  const { locSelection } = useSelector((state) => state.screenLoc);
  const dispatch = useDispatch();
  // for location
  const [currentLocation, setCurrentLocation] = useState(null);
  const [viewType, setViewType] = useState("list");
  const [selectedFlatFilter, setSelectedFlatFilter] = useState("50");
  const [selectedScreenFilter, setSelectedScreenFilter] = useState("5");
  const [selectedSorting, setSelectedSorting] = useState("name");
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedscreenId, setSelectedscreenId] = useState(null);
  const [isAddScreenModalVisible, setAddScreenModalVisible] = useState(false);
  const [isFilterModalVisible, setFilterModalVisible] = useState(false);

  const[searchLocation,setSearchLocation]=useState("");
  const[mapDilog,setMapDialog]=useState(false);
  const [mapData,setMapData]=useState("");
  const[mapInputs,setMapInputs]=useState("");
  const [anchorEl, setAnchorEl] = useState(null);

console.log('searching', searchLocation);

  const user = JSON.parse(sessionStorage.getItem("userData"));
  //console.log("userr", user);
  let content = locList;
  const handleOpenFilterModal = () => {
    setFilterModalVisible(true);
    setAnchorEl(document.getElementById("search-more-input"));
  };

  const handleCloseFilterModal = () => {
    setFilterModalVisible(false);
  };


  const handleOpenAddScreenModal = () => {
    setAddScreenModalVisible(true);
  };
  const handleCloseAddScreenModal = () => {
    setAddScreenModalVisible(false);
  };
  const handleOpenModal = (screenId) => {
    setSelectedscreenId(screenId);
    setEditPopupVisible(true);
  };

  const handleCloseModal = () => {
    setEditPopupVisible(false);
  };

  const handleFlatFilterChange = (event) => {
    setSelectedFlatFilter(event.target.value);
  };

  const handleSortingChange = (event) => {
    setSelectedSorting(event.target.value);
    sortFiltered(event.target.value);

  };

  const handleScreenFilterChange = (event) => {
    setSelectedScreenFilter(event.target.value);
  };

  const handleViewTypeChange = (type) => {
    setViewType(type);
  };
  const handleToggleView = () => {
    setViewType((prevType) => (prevType === 'list' ? 'map' : 'list'));
  };
  const updateLocSelection = (locId) => {
    if (!locId) return;
    let locUpdate = JSON.parse(JSON.stringify(locSelection));
    if (locId && locSelection.indexOf(locId) == -1) {
      locUpdate.push(locId);
    }
    else if (locId && locSelection.indexOf(locId) != -1) {
      let locIdIndx = locSelection.indexOf(locId);
      locUpdate.splice(locIdIndx, 1);
    }
    dispatch(setScreenLocationSelection(locUpdate));
  };

  const renderLocationIcon = (locationType) => {
    if (locationType === "Apartment") {
      return <ApartmentIcon />;
    } else if (locationType === "Events") {
      return <DateRangeIcon />;
    } else if (locationType === "Restaurant") {
      return <RestaurantIcon />;
    } else {
      return <CorporateFareIcon />;
    }
  };
 // console.log("user", currentUser);
if (currentUser) {
  console.log("useis logedr", currentUser);
} else {
  console.log("User is not logged in");
}

  const renderPlayers = () => {
    //https://digisign24.s3.ap-south-1.amazonaws.com/
    let contentlist = filteredContent;
     //console.log(contentlist);
    contentlist = contentlist.filter(player => player.cityName === SelectedCity);
    if (!contentlist.length) return <div>No Locations</div>;
    if(contentlist.length && selectedSorting=='pin') return <div >{renderPlayersTable(contentlist)}</div>;
    //console.log(contentlist);
    return contentlist && contentlist.map((p) => (
      <div className=" col-sm-12 col-md-6 col-lg-6 mt-4">
        <div key={p.id} className='card'
        >
          {false && p.id && <img
            className="card-img-top"
            src={`${LOC_GALLERY_URL}/${p.id}/${p.locationGallery.split(",")[0]
              }`}
            alt=" "
          />}
          <div className="card-body" >
          <h5 className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
             <div> <span>{p.locationType && renderLocationIcon(p.locationType)}</span>

             {currentUser && currentUser.role=="SUPER-ADMIN" ? (
              p.playerName
            ) : (
              <>
                {p.playerName.slice(0, 3)}
                <span style={{ filter: "blur(5px)" }}>
                  {p.playerName.slice(3)}
                </span>
              </>
            )}
              </div>
              {currentUser && currentUser.role=="SUPER-ADMIN"  && (
                 <p style={{ color: 'green'}}>{p.provider}</p>
                )}
              {p.dist && <div>
                <PlaceIcon />
                <p className="badge bg-info text-wrap">{p.dist} KM</p>
              </div>}
            </h5>
            <p className="card-text">
            {currentUser || user? (
              p.address +' PIN: ' +p.locationPinCode
            ) : (
              <>
                {p.address.slice(0, 10)}
                <span style={{ filter: "blur(5px)" }}>
                  {p.address.slice(10)}
                </span>
              </>
            )}
          </p>
            <div className="desktop-view">
              <div className="property-info">
                <span style={{ color: '#5d5b5b', fontSize: '0.9em' }}>#Screens</span>
                <span style={{ width: 'auto', color: 'blue', textAlign: 'center', fontWeight: 'bold', marginLeft: "3px" }}>{p.noOfExtendedScreens || 1}</span>
                <span style={{ color: '#5d5b5b', fontSize: '0.9em', marginLeft: '10px' }}>#Flats</span>
                <span style={{ color: '#a71c93', textAlign: 'center', fontWeight: 'bold', marginLeft: '3px' }}>{p.ip || 150}</span>
                {currentUser && currentUser.role =="SUPER-ADMIN" && (
                  <>
                    <button
                      onClick={() => handleOpenModal(p._id ? p._id : p.id)}
                      className="btn btn-warning ml-2 mt-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        ScreenLocService.deleteScreens(p._id ? p._id : p.id);
                      }}
                      className="btn btn-danger ml-2 mt-2"
                    >
                      Del
                    </button>
                  </>
                )}
              </div>
              <div className="property-info">
                {false && <Link className="btn btn-info mr-3" to={"/locations-details/" + (p._id ? p._id : p.id)} style={{ float: "right" }}>
                  <VisibilityIcon />
                </Link>}
                {locSelection.indexOf(p._id ? p._id : p.id) === -1 ? (
                  <button
                    onClick={() => updateLocSelection(p._id ? p._id : p.id)}
                    className="btn btn-primary"
                  >
                    ADD
                  </button>
                ) : (
                  <span
                    onClick={() => updateLocSelection(p._id ? p._id : p.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="right-tick"></span>
                    {" ADDED"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  };

  const renderPlayersTable = (contentlist) => {

    return contentlist.map((p) => (
      <ul  style={{paddingLeft:'5px'}}>
        <li key={p.id}
          style={{ listStyle: "none", borderBottom: "1px solid #ccc" }}>
          {false && p.id && <img
            className="card-img-top"
            src={`${LOC_GALLERY_URL}/${p.id}/${p.locationGallery.split(",")[0]
              }`}
            alt=" "
          />}
          <div >
            <h5 >
            {currentUser && currentUser.role =="SUPER-ADMIN" && (
                 <p style={{ color: 'green'}}>{p.provider}</p>
                )}
              <span className="">{renderLocationIcon(p.locationType)}</span>
              {currentUser ? p.playerName
             : (
              <>
                {p.playerName.slice(0, 3)}
                <span style={{ filter: "blur(5px)" }}>
                  {p.playerName.slice(3)}
                </span>
              </>
            )}
              {<div style={{ display: "inline-block", float: "right", height: '20px' }}>
                <PlaceIcon />
                <p className="badge bg-info text-wrap">{p.dist} KM</p>

              </div>}
            </h5>
            <div style={{ float: 'right' }}>
              <span style={{ color: '#5d5b5b', fontSize: '0.9em' }}>#Screens</span>
              <span style={{ width: 'auto', color: 'blue', textAlign: 'center', fontWeight: 'bold', marginLeft: "3px" }}>{p.noOfExtendedScreens || 1}</span>
              <span style={{ color: '#5d5b5b', fontSize: '0.9em', marginLeft: '10px' }}>#Flats</span>
              <span style={{ color: '#a71c93', textAlign: 'center', fontWeight: 'bold', marginLeft: '3px' }}>{p.ip || 150}</span>
              {currentUser && currentUser.role =="SUPER-ADMIN"&& (
                <>
                  <button
                    onClick={() => handleOpenModal(p._id ? p._id : p.id)}
                    className="btn btn-warning ml-2 mt-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      ScreenLocService.deleteScreens(p._id ? p._id : p.id);
                    }}
                    className="btn btn-danger ml-2 mt-2"
                  >
                    Del
                  </button>
                </>
              )}
            </div>
            <p style={{ fontSize: "0.8em" }} className="card-text">{p.address +' PIN: ' +p.locationPinCode}</p>
            <div className="desktop-view">

              {false && <div className="property-info">
                <Link className="btn btn-info mr-3" to={"/locations-details/" + (p._id ? p._id : p.id)} style={{ float: "right" }}>
                  <VisibilityIcon />
                </Link>
                {false && locSelection.indexOf(p._id ? p._id : p.id) == -1 ? (
                  <button
                    onClick={() => updateLocSelection(p._id ? p._id : p.id)}
                    className="btn btn-primary"
                  >
                    SELECT
                  </button>
                ) : (
                  <span>
                    <span className="right-tick"></span>
                    {" WishList"}
                  </span>
                )}
              </div>}
            </div>
          </div>
        </li>
      </ul>
    ));

  };

  const downloadLocations = ()=>{

      let jsPdf = new jsPDF('p', 'pt', 'letter');
    var htmlElement = document.getElementById('locations-list');
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
        callback: function (jsPdf) {
            jsPdf.save("locations.pdf");
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [20, 20, 20, 20],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .7
        }
    };

     jsPdf.html(htmlElement, opt);

    //   html2canvas(document.getElementById('locations-list')).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   pdf.save("download.pdf");
    // });

     // htmlToImage.toPng(document.getElementById('locations-list'), { quality: 0.95 })
     //    .then(function (dataUrl) {
     //      var link = document.createElement('a');
     //      link.download = 'locations-list.jpeg';
     //      const pdf = new jsPDF();
     //      const imgProps= pdf.getImageProperties(dataUrl);
     //      const pdfWidth = pdf.internal.pageSize.getWidth();
     //      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
     //      pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);
     //      pdf.save("locations-list.pdf"); 
     //    });
  }

  useEffect(() => {
    //console.log(currentLocation);
    if (currentLocation) {
      getKilometers(SelectedCity);
    }
  }, [locList, currentLocation]);

  useEffect(() => {
    if (!content.length) {
      // UserService.getPublicContent().then(
      //   (response) => {
      //     //setContent(response.data);
          UserService.getPartnersContent()
            .then(res => {
              //let mergedContent = response.data;
              let sortedData = res.data.sort(function (a, b) {
                return a.playerName.localeCompare(b.playerName);
              });
              //setContent(sortedData);
              dispatch(setScreenLocationList(sortedData));
              if (!currentLocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ latitude, longitude });
                    getKilometers(SelectedCity, currentLocation);

                  },
                  (error) => {
                    console.log(`Error retriving current location: ${error.message}`);

                  }
                );
              }

           // });
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          //setContent(_content);
        }
      );

    }



  });



  const getDistance = (filteredLoc, indx, currentLoc) => {
    if (indx + 1 < filteredLoc.length) {
      let distanceInKm = 0;
      if (currentLoc) {
        let screenLocaiton = {
          latitude: filteredLoc[indx].latitude,
          longitude: filteredLoc[indx].longitude
        };
        distanceInKm = geolib.getDistance(currentLoc, screenLocaiton, 1, 1) / 1000;
        distanceInKm = distanceInKm.toFixed(2);
        filteredLoc[indx]['dist'] = distanceInKm;
        indx = indx + 1;
        getDistance(filteredLoc, indx, currentLoc);
      }
    }
    return filteredLoc;
  }

  const sortFiltered = (by) => {
     let filteredLoc = filteredContent;
     //console.log(filteredLoc);

     if(by.toLowerCase() == 'pin'){
      filteredLoc.sort(function(a, b){
    return parseInt(a.locationPinCode) - parseInt(b.locationPinCode);
});
     } 
   
    setFilteredContent(filteredLoc);
  }

  const setNewRange = (e) => {
    // console.log(currentLocation);
    let kmVal = e ? e.target.value : 2;
    if(e){
    setSelectedRange(kmVal);
    }
    if (currentLocation) {

      getKilometers(SelectedCity, currentLocation, kmVal);
    }
  }

  const getKilometers = (city, currentLoc, range) => {
    let selCityName = city ? city : SelectedCity;
    let from = currentLoc ? currentLoc : currentLocation;
    let nwRng = range ? range : SelectedRange;
    //filter by City name
    //console.log(content);
    let filteredLoc = JSON.parse(JSON.stringify(content)).filter(c => c.cityName && c.cityName.toLowerCase() == selCityName.toLowerCase());
    //filter by range 

    filteredLoc = getDistance(filteredLoc, 0, from);
    //console.log(filteredLoc);
    if (nwRng) {
      filteredLoc = filteredLoc.filter((loc) => parseFloat(loc.dist) <= nwRng);
    }
    setFilteredContent(filteredLoc);
    setApartCount(filteredLoc.length);
    let count = 0;
    filteredLoc.map(loc => count = count + loc.noOfExtendedScreens);
    setScrnCount(count);
    flatsCount(filteredLoc);
    //return filteredLoc;
  }

  const pinClick = () => {

  }

  const getInfoBoxes = (locations) => {
    let pushPins = [];
    locations.map(l => {
      //console.log(l);
      if (l.latitude && l.longitude) {
        l.latitude.replace('° N', '');
        l.longitude.replace('° N', '');
        return pushPins.push(
          {
            location: [parseFloat(l.latitude), parseFloat(l.longitude)],
            addHandler: "mouseover",
            "infoboxOption": { title: l.playerName, description: l.address },
            "infoboxOption": { title: l.playerName, description: l.address },
            "pushPinOption": { title: l.playerName, description: l.address },
            "infoboxAddHandler": { "type": "click", callback: pinClick },
            "pushPinAddHandler": { "type": "click", callback: pinClick }


          });
      } else { return true; }

    });
    //console.log(pushPins);
    return pushPins;
  }

  const BingMaps = ({ locations }) => {
    //console.log('locations', locations);
    return (

      <div style={{ height: 600 }} id="map-view">
        <ReactBingmaps
          bingmapKey="ArW_TkF5xKfdKeIe4Ac-IQaAI7Mm3FLkRbPaj0g5EqEvF01MqV5JMR-ABy2BxrPd"
          center={[13.02889, 77.54316]}
          infoboxesWithPushPins={getInfoBoxes(locations)}
          zoom={12}
        >
        </ReactBingmaps>
      </div>
    );
  }

  const addAllFitlered = (e) => {
    //console.log(e);
    let locIds =[];
    if(e.target.checked){
    filteredContent.map(loc=> locIds.push(loc._id));
    }
    dispatch(setScreenLocationSelection(locIds));
  }

  const handleSearch = (e) => {

    setDisplay(true);
    let inputVal = e && e.target.value ? e.target.value : "";
    let contents = checked ? otherList : content;
    let filterByName = contents.filter((list) => list.playerName.toLowerCase().indexOf(inputVal.toLowerCase()) != -1 || list.address.toLowerCase().indexOf(inputVal.toLowerCase()) != -1);
    setFilteredContent(filterByName);

  }


  const handleCityChange = (e) => {
    //console.log(e.target.value);
    setSelectedCity(e.target.value);
    if (currentLocation) {
      getKilometers(e.target.value, currentLocation, SelectedRange);
    }
  }

  const handleStaus = (e) => {
    setDisplay(true);
    let statusVal = e.target.value
    if (statusVal.toUpperCase() === "ACTIVE") {
      let contents = checked ? otherList : content;
      let filterByStatus = contents.filter((active) => active.screenNumber);
      setFilteredContent(filterByStatus);

    }
    else if (statusVal.toUpperCase() === "PROGRESS") {
      let contents = checked ? otherList : content;
      let filterContent = contents.filter((progress) => progress.screenNumber === "  ");
      setFilteredContent(filterContent);

    }
    else { console.log("no data foound"); }
  }


  const getArea = () => {
    const result = [];
    for (let i = 1; i < 9; i++) {
      result.push(<MenuItem key={i} value={i}>{i} km</MenuItem>);
    }
    for (let i = 10; i <= 70; i = i + 5) {
      result.push(<MenuItem key={i} value={i}>{i} km</MenuItem>);
    }
    return result;
  };


  const flatsCount = (contentList) => {
    let allFlats = 0;
    //console.log(contentList);
    contentList.forEach((p) => {
      if (p.ip) {
        allFlats += parseInt(p.ip, 10);
      }

    });
    //console.log(allFlats);
    return setTotalIP(allFlats);
  };

 const handleClose = () => {
    setAnchorEl(null);
  };

const  handleMapSearch =()=>{
  //console.log("MapSeach");
  let searchInput = document.getElementById("search-more-input");
  console.log(searchInput);
  setAnchorEl(searchInput);
  axios.get(`https://dev.virtualearth.net/REST/v1/Locations?q=${searchInput.value}&o=json&maxResults=5&key=ArW_TkF5xKfdKeIe4Ac-IQaAI7Mm3FLkRbPaj0g5EqEvF01MqV5JMR-ABy2BxrPd`).then(res=>{
    //console.log(res.data.resourceSets);
    setMapData(res.data.resourceSets);
    
    setOpen(true);
  })
} 

 
 const updateLatLongInCurrent=(lat,long)=>{
  let currLoc = currentLocation;
  if(lat && currLoc.latitude){
  currLoc.latitude = lat;
  }
  if(long && currLoc.longitude){
      currLoc.longitude = long;
  }
  setCurrentLocation(currLoc); 
 }

 const PaperComponent =(props: PaperProps) =>{
  return (
    <Draggable
      handle="#filter-modal"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

  const FilterModal = () => {
   
    return (
      <Dialog
        open={isFilterModalVisible}
        onClose={handleCloseFilterModal}
        aria-labelledby="filter-modal"
        aria-describedby="filter-options"
        /*sx={{ml: '10px', mr: '10px'}}*/
        maxWidth="md"
            PaperComponent={PaperComponent}
      >
        <DialogTitle id="filter-modal" style={{minWidth:"400px",fontWeight:"bold",cursor: 'move'}}>Advanced Filter</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFilterModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
          <DialogContent dividers>   
          <Grid item xs={8} sx={{ margin: "10px",marginTop:"0px"}}>
            <div  style={{width:"auto",marginTop:"0px",marginBottom:"10px",display:"flex",justifyContent:"space-between",alignItems:"center"}} >
              <TextField  size="small"
              placeholder={"MG Road, Bangalore"}
              name="From"
              label={"Search Location"}
              id={"search-more-input"}
              aria-describedby={"find-more"}
              defaultValue={searchLocation}
              style={{width:"290px", border: 'none', outline: 'none', backgroundColor: "#fefeff00" }}
              onBlur={(e)=>setSearchLocation(e.target.value)}
            />

                <div
            id={"find-more"}
            style={{display:open?'block':'none',position:'absolute',top:"125px",zIndex:3,border:"1px solid #ccc",backgroundColor:"#f4f4f4",height:"200px",boxShadow: '-1px 2px 5px 5px rgb(191 191 191 / 78%)'}}
          >
          {
                mapData.length?mapData.map(md=>{

                  return(
                    md.resources.map(map=>{
                      return (
                         <div className="loc_grid" >
                               <Typography onClick={()=>(setSearchLocation(map.name),updateLatLongInCurrent(map.point.coordinates[0],map.point.coordinates[1]),setOpen(false),setNewRange(),handleClose())} sx={{ p: 2 }}>{map.name}</Typography>
                          </div>
                      )
                    })
                  )
                }):""
              }
            
          </div>
            <Button style={{marginLeft:"10px",cursor:"pointer", border:"1px solid #ccc"}}  onClick={()=>handleMapSearch()} >Find</Button>
            </div>
            <div style={{fontSize:"0.8em",color:"#838383",textAlign:"center",marginBottom:"10px"}}>{'----OR---'}</div>
            <TextField label={"Latitude"} size="small" onBlur={(e)=>updateLatLongInCurrent(e.target.value,null)} placeholder="Latitude" value={currentLocation&&currentLocation.latitude}  variant="outlined" style={{ marginRight:"10px"}}/>
            <TextField label={"Longitude"} size="small" onBlur={(e)=>updateLatLongInCurrent(null,e.target.value)} placeholder="Longitude"  value={currentLocation&&currentLocation.longitude}  variant="outlined" />
          </Grid>
          <Grid item xs={8} sx={{ marginLeft: "20px", marginBottom: '15px'}}>
          <span style={{width:"250px",display:"inline-block"}}>Range(with in):</span>
            <Select
              labelId="range-select-small-label"
              id="range-select-small"
              value={SelectedRange}
              label="Range"
              name="Range"
              size="small"
              onChange={(e) => (setNewRange(e), getKilometers())}
            >
              <MenuItem value={'0.2'}>{'With In'}</MenuItem>
              {getArea()}
            </Select>
          </Grid>
          <Grid item xs={6} sx={{ marginLeft: "20px", marginBottom: '15px' }}>
            <span style={{width:"250px",display:"inline-block"}}>No. of flats(above):  </span>
            <Select
              labelId="flat-select-small-label"
              id="flat-select-small"
              value={selectedFlatFilter}
              onChange={handleFlatFilterChange}
              size="small"
            >
              {getOptions(50, 2000, 50)}
            </Select>
          </Grid>

          <Grid item xs={6} sx={{ marginLeft: "20px", marginBottom: '15px' }}>
            <span style={{width:"250px",display:"inline-block"}}>Sort By:  </span>
            <Select
              labelId="sort-select-small-label"
              value={selectedSorting}
              onChange={(e)=>handleSortingChange(e)}
              size="small"
            >
              <MenuItem key={`name`} value={'name'}>{'Location Name'}</MenuItem>
              <MenuItem key={`distance`} value={'distance'}>{'Distance'}</MenuItem>
              <MenuItem key={`pin`} value={'pin'}>{'Pin Code'}</MenuItem>
            </Select>
          </Grid>
           </DialogContent>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between",padding:"10px"}}>
          <Button  onClick={handleCloseFilterModal} color="secondary" >Close</Button>
        <Button variant="contained" onClick={setNewRange,handleCloseFilterModal}>Apply</Button>
        </div>
       
      </Dialog>
     
    );
  };

  const handleLocation =(e)=>{
    //console.log(e.target.value);
    setMapInputs(e.target.value);
  }

  const mapSearchView = ()=>{
    
    return(
      <Dialog
      open={mapDilog}
      onClose={()=>setMapDialog(false)}
      aria-labelledby="filter-modal"
      aria-describedby="filter-options"
      // sx={{ml: '10px', mr: '10px'}}
      maxWidth="md"
      // sx={{padding:"10px"}}
      >
          <DialogTitle align="center">Select Location</DialogTitle>
        <div style={{height:"380px",width:"550px",padding:"15px",paddingLeft:"35px"}}>
          
            <div style={{width:"100%",}}>
              <input type="text" onChange={(e)=>setMapInputs(e.target.value)} placeholder="Enter mannually  Lat,Long by(,)" style={{width:"90%",borderRadius:"8px",outline:"none",padding:"6px",margin:"10px"}}/>
            </div>
            {/* "point": {
                        "type": "Point",
                        "coordinates": [
                            12.97674656,
                            77.57527924
                        ]
                    }, */}
        <div style={{height:"320px",overflow:"auto",margin:"10px"}}>
              <div className="loc_grid" style={{border:"none"}}>
                Select Location 
              </div>
              {
                mapData.length?mapData.map(md=>{

                  return(
                    md.resources.map(map=>{
                      return (
                         <div className="loc_grid" >
                               {map.name} <input type="radio" onChange={handleLocation} value={map.point.coordinates} name="location" style={{width:"25px",height:"23px"}}/>
                          </div>
                      )
                    })
                  )
                }):""
              }
        </div>
        
        </div>
        <div style={{padding:"15px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <button className="btn btn-danger m-2" onClick={()=>setMapDialog(false)}>Close</button>
          <button className="btn btn-success m-2"  onClick={handleMapApply}>Apply</button>
        </div>
      </Dialog>
    )
  }
  const handleMapApply =()=>{
      if(mapInputs){
        let value=mapInputs.split(",")
        //console.log(value);
        let lat=value[0];
        let long=value[1]
        setCurrentLocation({ lat, long });
        setMapDialog(false);
        setFilterModalVisible(false);
      }
  }

  return (
    <div className="container ">
      <header className="jumbotron" style={{ marginBottom: "0px!important" }}>
        <h3>Locations</h3>
        <div>
          {false && <InputLabel id="city-select-small-label">City</InputLabel>}
          <Select
            labelId="city-select-small-label"
            id="city-select-small"
            value={SelectedCity}
            label="City"
            size="small"
            onChange={handleCityChange}
          >
            <MenuItem value={'Bangalore'}>{'Bangalore'}</MenuItem>
            <MenuItem value={'Chennai'}>{'Chennai'}</MenuItem>
            <MenuItem value={'Hyderabad'}>{'Hyderabad'}</MenuItem>
            <MenuItem value={'Mumbai'}>{'Mumbai'}</MenuItem>
          </Select></div>
      </header>
      <h6 style={{ marginTop: "-10px" }}>{"Please select your target locations"}</h6>

      
      <div>
        <form className='searchTab' style={{ display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" }}>
          {<div className='searchBar' style={{ visibility: (isMapView ? "hidden" : "visible") }}>
            <SearchIcon />
            <input type="text" placeholder="Name, area, zip... " onChange={handleSearch} style={{ border: 'none', outline: 'none', backgroundColor: "#fefeff00" }} />
          </div>}
          <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: "11px" }}>
            
            <span>

              {/* filter for flat and screen */}
              {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "wrap", marginBottom: '10px' }}>
                <div style={{ marginRight: "10px" }}>
                  <span style={{ marginRight: "5px" }}>Flat:</span>
                  <select value={selectedFlatFilter} onChange={handleFlatFilterChange}>
                    {Array.from({ length: 41 }, (_, i) => i * 50 + 50).map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <span style={{ marginRight: "5px" }}>Screens:</span>
                  <select value={selectedScreenFilter} onChange={handleScreenFilterChange}>
                    {[...Array(5).keys()].map((index) => (
                      <option key={index} value={(index + 1) * 10}>
                        {index === 0 ? "1-10" : `${index * 10 + 1}-${index * 10 + 10}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
              <Select
                labelId="range-select-small-label"
                id="range-select-small"
                value={SelectedRange}
                label="Range"
                size="small"
                onChange={setNewRange}
                sx={{ mr: 2 }}
              >
                <MenuItem value={'0.2'}>{'With In'}</MenuItem>
                {getArea()}
              </Select>
              <Button
                variant="contained"
                startIcon={<FilterAltIcon />}
                id={"more-btn"}
                onClick={()=>handleOpenFilterModal()}
              >
                More
              </Button>
            </span>
          </div>
        </form>
        {dispaly && filteredContent.length === 0 ? <span style={{ color: "red" }}>No data found!</span> : ""}
      </div>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: "11px", backgroundColor: '#f9fbfd' }}>
        {filteredContent.length ?<FormControlLabel control={<Checkbox onClick={addAllFitlered} />} label="ADD ALL" />:null}
        <span>
          {currentUser && currentUser.role&&currentUser.role.indexOf("SUPER-ADMIN")!= -1 && (
            <>
              <button
                className="btn btn-info ml-2 mb-2"
                onClick={handleOpenAddScreenModal}
              >
                Add Location
              </button>
            </>
          )}
          
          <Button
            variant="outlined"
            color="primary"
            onClick={handleToggleView}
            style={toggleButtonStyle}
          >
            {viewType === 'list' ? 'Map View' : 'List View'}
          </Button>
          
        </span>
        
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center",borderBottom:"1px solid #ccc" }}>
                  
        {filteredContent.length && apartCount ? <span style={{ fontSize: "0.9em", display: "flex", alignItems: "center", marginTop: "18px" }}>#Locations<span style={{ color: "green", fontWeight: "bold", fontSize: "22px", width: "auto", borderRadius: "5px", marginLeft: "3px" }}>{apartCount}

        </span></span> : ""}
        {filteredContent.length && scrnCount ? <span style={{ fontSize: "0.9em", display: "flex", alignItems: "center", marginLeft: "7px", marginTop: "18px" }}>#Screens<span style={{ color: "blue", fontSize: "22px", fontWeight: "bold", width: "auto", borderRadius: "5px", marginLeft: "2px" }} > {scrnCount}</span></span> : ""}
        {filteredContent.length && totalIP ? (
          <span style={{ fontSize: "0.9em", display: "flex", alignItems: "center", marginTop: "18px", marginLeft: "7px" }}>
            #Flats<span style={{ color: "#a71c93", fontSize: "22px", fontWeight: "bold", width: "auto", borderRadius: "5px", marginLeft: "3px" }}>
              {totalIP}
            </span>
          </span>
        ) : null}
        <span style={{marginLeft:'20px',marginTop:"15px",cursor:'pointer'}}><GetAppIcon onClick={()=> downloadLocations()}/></span>
      </div>
      {viewType === "map" && filteredContent.length && <BingMaps locations={filteredContent} />}
       <div className='row' id="locations-list" style={{display:selectedSorting === "pin"?'block':'flex'}}>{renderPlayers()}</div>
     
      {isEditPopupVisible && (
        <EditPopup handleClose={handleCloseModal} open={isEditPopupVisible} screenId={selectedscreenId} />
      )}
      {isAddScreenModalVisible && (
        <AddScreen handleClose={handleCloseAddScreenModal} open={isAddScreenModalVisible} />
      )}
      {isFilterModalVisible && FilterModal()}
      
    </div>
  )
};

export default Home;
