import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import {authUrl, getParameterByName} from './constants';
import App from "./App";
//import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";

const token=getParameterByName('token');
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      {/* { (token || sessionStorage.getItem("token") ? <App /> : window.location.href = authUrl +  "/account/login?redirect=" + window.location.origin)} */}
      {!authUrl && document.write("Loading! Please wait...")}
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
