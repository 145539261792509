import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../constants";

const SUB_PATH = "/api/digi";

const getPublicContent = () => {
  return axios.get(API_URL +SUB_PATH+ "/all");
};

const getUserBoard = () => {
  return axios.get(API_URL +SUB_PATH+ "/user-board", { headers: authHeader() });
};

const getUserDetails = () => {
  return axios.get(API_URL +SUB_PATH+ "/user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL +SUB_PATH+ "/mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL +SUB_PATH+ "/admin", { headers: authHeader() });
};


const getPartnersContent=()=>{
  return  axios.get("https://cms.digitallive24.com/api/partner-screens") ;
}

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getPartnersContent,
};