import axios from "axios";
import authHeader from "./auth-header";
import {API_URL, BASE_URL} from "../constants";

const SUB_PATH = "/api/partner-screens";

const getScreenDetails = (_id) => {
  return axios.get(BASE_URL+SUB_PATH+ "/"+_id);
};
const deleteScreens = (_id) => {
  return axios.delete(BASE_URL+SUB_PATH+"/"+_id)
              .then((response) => {
                console.log("Delete success", response);
                
              })
              .catch((error) => {
                console.error("Delete error", error);
              });
};
const editScreens = (_id, data) => {
  return axios.put(BASE_URL + SUB_PATH + '/' + _id, data)
              .then((response) => {
                console.log("Edit success", response);
              })
              .catch((error) =>{
                console.error("Edit error", error);
              });
}
const addScreens = (data) => {
  return axios.post(BASE_URL + SUB_PATH , data)
              .then((response) => {
                console.log("Edit success", response);
              })
              .catch((error) =>{
                console.error("Edit error", error);
              });
}

// const getUserBoard = () => {
//   return axios.get(API_URL + "user", { headers: authHeader() });
// };

// const getModeratorBoard = () => {
//   return axios.get(API_URL + "mod", { headers: authHeader() });
// };

// const getAdminBoard = () => {
//   return axios.get(API_URL + "admin", { headers: authHeader() });
// };

export default {
  getScreenDetails,
  deleteScreens,
  editScreens,
  addScreens
};