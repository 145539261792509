export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";

export const SCREEN_LOC_LIST = "SCREEN_LOC_LIST";
export const SCREEN_LOC_SUCCESS = "SCREEN_LOC_SUCCESS";
export const SCREEN_LOC_FAIL = "SCREEN_LOC_FAIL";
export const SCREEN_LOC_SELECTION_UPDATE="SCREEN_LOC_SELECTION_UPDATE";
export const MEDIA_SELECTION="MEDIA_SELECTION";
