import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import screenLoc from "./screenLoc";
import media from "./media";
import user from "./user";


export default combineReducers({
  auth,
  message,
  screenLoc,
  media,
  user
});
