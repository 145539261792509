import {
  USER_DATA_SUCCESS
} from "./types";


export const setUserData = (data) => ({
  type: USER_DATA_SUCCESS,
  payload: data,
});

