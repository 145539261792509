import React, { useState, useEffect } from "react";
import { Navigate, useParams, useLocation } from 'react-router-dom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, DialogTitle, Dialog, DialogContentText, InputLabel, Box, Select, FormControl, MenuItem, DialogContent, DialogActions, IconButton } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import toast from 'react-hot-toast';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const today = new Date();
const minStartDate = today.toISOString().split('T')[0];
const oneMonthLater = new Date(today);
oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
const defaultEndDate = oneMonthLater.toISOString().split('T')[0];

const Footer = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { locSelection, locList } = useSelector(state => state.screenLoc);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [times, setTimes] = useState(180);
  const [bestTotalPayAppx, setBestTotalPayAppx] = useState(0);
  const [campaignType, setCampaignType] = useState('');
  const [startDate, setStartDate] = useState(minStartDate);
const [endDate, setEndDate] = useState(defaultEndDate);
const [selectedLocations, setSelectedLocations] = useState([]);
const [totalScreens, setTotalScreens] = useState(0);
  const [locDetails, setLocDetails] = useState('');
  const dispatch = useDispatch();
  const loc = useLocation();
   const notify = (msg) => toast.success(msg);
   const failedNotify=(msg) => toast.error(msg);
  //console.log(loc);

  console.log('Logged in', currentUser.name);  

  const handleOpenModal = () => {
    let totScreens=0;
    let locNames='';
    let list =locList.map((loc,i) => {
      if(locSelection.indexOf(loc._id) != -1){
      locNames =locNames+'<tr><td>'+loc.playerName+'</td><td>'+loc.address+'</td><td>'+loc.noOfExtendedScreens+'</td></tr>';
      totScreens = totScreens+ loc.noOfExtendedScreens;
    }
    if(i == locList.length-1){
      ///console.log(totScreens);
      setTotalScreens(totScreens);
      setLocDetails(locNames);
      getAppxTotalEst(totScreens);
    }
      return true;
    
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleChange = () => {

  };
  //console.log(locList);
  useEffect(() => {
    // Update selectedLocations when locSelection changes
    //setSelectedLocations(locSelection);
    
    
    
  });

  const getAppxTotalEst = (screensSelected)=>{
    const multiplicationFactors = {
      180: 29,
      360: 49,
      720: 69
    };
    const payAppx =0;
    if (startDate && endDate) {
      const screens = screensSelected||totalScreens;
      const oneDay = 24 * 60 * 60 * 1000;
      const start = new Date(startDate);
      const end = new Date(endDate);
      const days = Math.round(Math.abs((end - start) / oneDay));
      const payAppx = screens * days * multiplicationFactors[times];
      setBestTotalPayAppx(payAppx);
    }
    return payAppx;
  }
  useEffect(() => {
      let totalEst = getAppxTotalEst();
      
  }, [startDate, endDate, times]);
  const sendEmail = () => {
    const emailData = `<html>
    <style>
      table tr, table tr td {
        border:"1px solid #ccc";
        height: "auto";

      }
    </style>
    <body>
    <table>
    <tr style="font-size: 1.2em, padding:5px">New campaign request from <b>${currentUser.name}</b> for Total <b>${locSelection.length}</b> locations having Total ${totalScreens} screens 
    </tr>

    <tr>
    <td> Screens Details</td>
    <td> ${locDetails}</td>
    </tr>

    <tr>
    <td> Start Date</td>
    <td> ${startDate}</td>
    </tr>

    <tr>
    <td> End Date</td>
    <td> ${endDate}</td>
    </tr>

    <tr>
    <td> Estimation Total </td>
    <td> ${bestTotalPayAppx}</td>
    </tr>

    <tr>
    <td> Other Campaign</td>
    <td> ${campaignType}</td>
    </tr>

    </table>
    </body>      

    </html>`;

    axios.post("https://cms.digitallive24.com/api/send-emails", {
      to: 'digilive24@gmail.com',
      subject: "[New Ad Quotation] Share new quotation",
      text: emailData
    })
      .then((response) => {
        console.log('Email sent:', response);
        handleCloseModal();
        notify("Thank You!, Team will contact you soon.");
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        handleCloseModal();
        failedNotify("Sorry, Something went wrong, please call us- 7019910793");
      });
  };
  const cartCountStyle = {
    position: "relative",
    display: "inline-block",
    marginRight: "8px",
  };

  const countNumberStyle = {
    position: "fixed",
    bottom:"30px",
    color: "red",
    padding: "0px 5px",
    borderRadius: "33%",
    fontSize: "larger",
    background: '#ede32b'
  };
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };
  const isCartEmpty = locSelection.length === 0;
  const handleCheckboxChange = (value) => {
    setCampaignType((prevCampaignType) => {
      if (prevCampaignType.includes(value)) {
        return prevCampaignType.filter((type) => type !== value);
      } else {
        return [...prevCampaignType, value];
      }
    });
  };
  
  return (
    loc.pathname.indexOf('/publish-ad') == -1 ?
      <div className="footer">
        <div style={containerStyle}>
          <div className="cart-container">
            <span className="cart-icon">
              <ListAltIcon />
            </span>
            <span style={cartCountStyle}>
              <span style={countNumberStyle}>{locSelection.length}</span>
            </span>
          </div>
          <div>
            {loc.pathname.indexOf('/user') === -1 ? (
              <div>
                <Button onClick={handleOpenModal} variant="contained" sx={{ backgroundColor: "green" }}>
                  Customize Plan
                </Button>
                <Dialog open={isModalOpen} onClose={handleCloseModal} aria-labelledby="customized-dialog-title">
                  <div>
                    {isCartEmpty ? (
                      <div>
                        <DialogTitle>Your Campaign Plan</DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseModal}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                        <DialogContentText className="ml-3 mr-5">
                          Your cart is empty, click “Select” on locations of your choice to publish Ad.
                        </DialogContentText>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Button color="primary" variant="contained" className="mt-3" onClick={handleCloseModal}>
                              GO TO SELECT
                            </Button>
                            <DialogContentText className="mt-2">OR</DialogContentText>
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <FormControlLabel
                                className="mt-1"
                                control={
                                  <Checkbox
                                    checked={campaignType.includes("What’s App campaign")}
                                    onChange={() => handleCheckboxChange("What’s App campaign")}
                                  />
                                }
                                label="What’s App campaign"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={campaignType.includes("Social media campaign")}
                                    onChange={() => handleCheckboxChange("Social media campaign")}
                                  />
                                }
                                label="Social media campaign"
                              />
                            </Box>
                            <DialogContentText className="mt-3 mb-3">OR</DialogContentText>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label="Write few words with your requirement and contact details"
                              helperText="Our experts will reach out with a plan for you."
                              multiline
                              rows={4}
                            />
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button color="primary" variant="contained" className="ml-5 mt-2 mb-3" onClick={sendEmail}>
                            SEND
                          </Button>
                        </DialogActions>
                      </div>
                    ) : (
                      <>
                        <DialogTitle style={{fontWeight:"bold"}}>Your Campaign Plan</DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseModal}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                          <DialogContentText>
                            <label>You have selected <b>{locSelection.length } locations</b> . Having total <b>{totalScreens} Screens</b></label>
                          </DialogContentText>
                          <div style={{ marginTop: '20px' }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Loop per /day/screen</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={times}
                                label="Loop per /day/screen"
                                size="small"
                                onChange={(e) => setTimes(e.target.value)}
                              >
                                <MenuItem value={180}>180 times appx </MenuItem>
                                <MenuItem value={360}>360 times appx </MenuItem>
                                <MenuItem value={720}>720 times appx </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <div>
                              <InputLabel>Start Date:</InputLabel>
                              <input type="date" format={'dd/mmm/yyyy'} value={startDate} onChange={e => setStartDate(e.target.value)}  min={minStartDate} />
                            </div>
                            <div style={{ marginLeft: '15px'}}>
                              <InputLabel>End Date:</InputLabel>
                              <input type="date" format={'dd/mmm/yyyy'} value={endDate} onChange={e => setEndDate(e.target.value)}  min={startDate} />
                            </div>
                            </Box>
                          </div>
                          <h6 style={{color:'#1368ad'}}>Best Total Pay Appx.  ₹{bestTotalPayAppx} Only</h6>
                          <div style={{ marginTop: '5px' }}>
                            <FormControlLabel
                              className="mt-1"
                              control={
                                <Checkbox
                                  checked={campaignType.includes("What’s App campaign")}
                                  onChange={() => handleCheckboxChange("What’s App campaign")}
                                />
                              }
                              label="What’s App campaign"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={campaignType.includes("Social media campaign")}
                                  onChange={() => handleCheckboxChange("Social media campaign")}
                                />
                              }
                              label="Social media campaign"
                            />
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button color="primary" variant="contained" className="ml-5 mt-2 mb-3" onClick={sendEmail}>
                            Request Quote
                          </Button>
                        </DialogActions>
                      </>
                    )}
                  </div>
                </Dialog>
              </div>
            ) : (
              <Link to="/home" className="btn btn-info">
                Select Locations
              </Link>
            )}
          </div>
        </div>
      </div> : null
  );
};

export default Footer;