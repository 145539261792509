import React, { useState, useEffect } from "react";
import{BASE_URL,REPORTS_URL}from "../constants";
import axios from "axios";
import UserService from "../services/user.service";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import {MEDIA_GALLERY} from "../constants";

const Reports =()=>{
	const { userData } = useSelector(state => state.user);
	const { locList } = useSelector(state => state.screenLoc);
	const [playListItemsIds,setPlayListItemsIds]=useState([]);
	 const [content, setContent] = useState([]);
	 const [reportList,setReportList]=useState([]);
	 const [cmsScreens,setCmsScreens]=useState([]);
	 const[repItemStatus,setRepItemStatus]=useState([]);
	 const[repThumbs,setRepThumbs]=useState([]);
	 const[repDetails,setRepDetails]=useState([]);
	 
		
	console.log(userData);
 const getParameterById = (e, t = window.location.href) => {
    e = e.replace(/[\[\]]/g, "\\$&"); var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
    return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null;
  }
   const mediaId=getParameterById("id");
   const adName=getParameterById("adName");
   const thumb=getParameterById("thum");

useEffect(()=>{
	if(!playListItemsIds.length){
		axios.get(`${BASE_URL}/api/playlist-items-by-mediaid/${mediaId?mediaId:""}`)
	.then(res=>{
				console.log(res.data);
		setPlayListItemsIds(res.data);
	});
	}
},[]); 


console.log(playListItemsIds);
console.log(`${BASE_URL}/api/playlist-items-by-mediaid/${mediaId?mediaId:""}`);

 useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        console.log(response.data);
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  },[]);
	console.log(content);

// useEffect(()=>{
// 	let macNum=[];
// 			content.map(playerList=>{macNum.push(playerList.MAC)})
// 				console.log(macNum);
// 	axios.post(`${BASE_URL}/api/players/bymac`,{macs:macNum})
// 	.then((res)=>{console.log(res.data);
// 		setCmsScreens(res.data.screens);

// });
// },[content]);
// console.log(cmsScreens);


// useEffect(()=>{
// 	console.log("screenIds");
// 	let screenIds=[]
// 	cmsScreens.map(screen=>{
// 		screenIds.push(screen._id);
// 	});
// 	axios.post(`${REPORTS_URL}/reports/playlist-items-on-screens-played-count`,{
// 		screenIds:screenIds.join("~"),
// 		playlistItemsIds:playListItemsIds.join("~")
// 	})
// 	.then(res=>{ console.log(res.data);
// 		setRepItemStatus(res.data.ItemsStatusArr);
// 		setRepThumbs(res.data.thumbs);
// 	});

// },[cmsScreens]);
// console.log(repItemStatus);
// 														// screeen id                                             playlistItemsIds
// // console.log(repThumbs[0].imgPath.split("/")[1].split("=")[0],repThumbs[0].imgPath.split("/")[1].split("=")[1].split("_")[0]);
// // .imgPath.split("/")[1].split("=")[0]

	let playerApartmentList = [];
if(userData.length && locList.length){
	userData && userData.map(u =>
													  u.medias.map(p =>
													    p.ads.filter(ad => {
													      let filterData = ad.mediaId === mediaId ? ad.adLocs : "";
													      if (filterData.length) {
													        filterData.map(o => {
													          if (locList.length) {
													            locList.filter(list => {
													              if (o.playerId === list.id) {
													                let playerName = list.playerName;
													                playerApartmentList.push(list)
													               
													              }
													            });
													          }
													        });
													      }
													    })
													  )
													);
}

console.log(playerApartmentList);

const showCount=(macNum)=>{

	console.log(macNum);
				axios.post(`${BASE_URL}/api/players/bymac`,{macs:macNum})
	.then((res)=>{
					 console.log(res.data.screens);
				setCmsScreens(res.data.screens);
});
	console.log(cmsScreens);
	
}
useEffect(()=>{
	let screenId=[];
	cmsScreens.length&& cmsScreens.map(scrn=> screenId.push(scrn._id));
	console.log(screenId);
	if(screenId.length && playListItemsIds.length){
			axios.post(`${REPORTS_URL}/reports/playlist-items-on-screens-played-count`,{
		screenIds:screenId[0],
		playlistItemsIds:playListItemsIds.join("~")
	})
	.then(res=>{ console.log(res.data);
		setRepItemStatus(res.data.ItemsStatusArr);
		setRepThumbs(res.data.thumbs);
	});

	}

},[cmsScreens])

useEffect(()=>{
	if(repItemStatus){
	repItemStatus.map((rep)=>{
		cmsScreens.filter(cms=>{ 
			// rep.screenId===cms._id &&macDetais.push(cms.MAC)
			content.filter(mac=>{	
								rep.screenId===cms._id && cms.MAC===mac.MAC && setReportList(prev =>[...prev,{MAC:mac.MAC,playCount:rep.playCount,isPlaying:rep.isPlaying,playerName:mac.playerName,screenId:rep.screenId}]);
			})

		})
	})
}

},[repItemStatus]);
console.log(reportList);


useEffect(()=>{
	if(repItemStatus){
	repItemStatus.map((rep)=>{
		cmsScreens.map(cms=>{ 
			// rep.screenId===cms._id &&macDetais.push(cms.MAC)
			content.map(mac=>{	
							repThumbs.map(thum=>{
								
								rep.screenId===cms._id && cms.MAC===mac.MAC &&rep.screenId===thum.imgPath.split("/")[1].split("=")[0]&&rep.playlistItemId===thum.imgPath.split("/")[1].split("=")[1].split("_")[0]&&setRepDetails(prev =>[...prev,{MAC:mac.MAC,playCount:rep.playCount,playerName:mac.playerName,imgPath:thum.imgPath,createdAt:thum.createdAt,screenId:rep.screenId}]);
							}
						)
			})

		})
	})
}
},[repThumbs]);
console.log(repDetails);
localStorage.setItem("apartName","");
	localStorage.setItem("playCount","");
	localStorage.setItem("adImgPath",thumb);

if(reportList){
			localStorage.setItem("reportData",JSON.stringify(reportList));
		}
// console.log(localStorage.getItem("reportData"));

if(repDetails){
			localStorage.setItem("repDetails",JSON.stringify(repDetails));
		}
// console.log(localStorage.getItem("repDetails"));

const handleApartment=(apartName,playCount)=>{
		console.log(apartName);
		localStorage.setItem("apartName",apartName);
		localStorage.setItem("playCount",playCount);
}
console.log(localStorage.getItem("apartName","playCount"));

const handleApateDetails=()=>{
	localStorage.setItem("apartName","");
	localStorage.setItem("playCount","");
}	



return(
		<div className="container ">
	<div>
				<img src={MEDIA_GALLERY+"/"+thumb} style={{marginTop:"20px",marginLeft:"25px",height:"80px", width:"80px"}}  alt="Cardimage cap"/>
				<h6>{adName?adName.replace(".mp4",""):""}</h6>
		</div>
		<div style={{overflow:"auto", height:"auto"}}	>
				<table align='center' cellSpacing='10px'  cellPadding='5px'>
						<thead>
										<tr>
										<th>Apartment</th>
										<th align="center" >{reportList.length?"Play repeat count":"Action"}</th>
										<th align="center" >{reportList.length?"Current status":""}</th>
										<th></th>
										</tr>
						</thead>
						<tbody>
											{playerApartmentList.length&& playerApartmentList.map(list=>{
												 const filterReportList = reportList.find((item) => item.playerName === list.playerName);
												 console.log(filterReportList);
												return(<tr>
													<td>{list.playerName}</td>
													<td align="center">	
												{filterReportList?filterReportList.playCount:<button className="btn btn-warning btn-sm" onClick={()=>showCount(list.MAC)}>Show count</button>}
													</td>
														{filterReportList?<td align="center" >{filterReportList.isPlaying?<span className="blink_txt text-success highlight_grn">{'RUNNING'}</span>:<span className=" highlight_orng">{'PENDING'}</span>}</td>:""}
														{filterReportList?<td align="center">
																							<button onClick={_=>handleApartment(list.playerName,list.playCount)} className='btn btn-primary'>
																						<a href="./Report_2.html" style={{textDecoration:"none",color:"white"}} target="_blank">Reports</a>
																							</button> 

																						</td>:""}
													</tr>)
											})

											}
								</tbody>
					</table>


				</div>

		
		 	{/*!reportList.length ?<CircularProgress color="warning" thickness='4'/>
		<table align='center' cellSpacing='5px'  cellPadding='5px'>
		<thead align='center'>
		<tr>
			<th>Apartment</th>
			<th>Play repeat count</th> 
			<th>Current status</th>
			<th></th>
			</tr>
		</thead>
		<tbody align='center'>
		{
				reportList.length && reportList.map(list=>{
					
					return (<tr>
					<td>{list.playerName}</td>
					<td>{list.playCount}</td>
					<td >{list.isPlaying?<span className="blink_txt text-success highlight_grn">{'RUNNING'}</span>:<span className=" highlight_orng">{'PENDING'}</span>}</td>
					<td>
							<button onClick={_=>handleApartment(list.playerName,list.playCount)} className='btn btn-primary'>
						<a href="./Report_2.html" style={{textDecoration:"none",color:"white"}} target="_blank">Show report</a>
							</button> 

					</td>
					</tr>

			
				)})

		}
		</tbody>

		</table>*/}	
	

			
		 </div>
	)
}
export default Reports;