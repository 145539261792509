import { MenuItem } from "@mui/material";

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  };

export const  getOptions = (start, end, step) => {
  const options = [];
  for (let i = start; i <= end; i += step) {
    options.push(<MenuItem key={`option-${i}`} value={i}>{i}</MenuItem>);
  }
  return options;
};

// export const  getSorting = () => {
//   const options = ['Location Name','Distance', 'PIN Code'];
//   for (let i = 0; i <= options.length; i++) {
//     options.push(<MenuItem key={`option-${i}`} value={i}>{i}</MenuItem>);
//   }
//   return options;
// };