import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login } from "../actions/auth";
import {authUrl, getParameterByName} from '../constants';
import Home from "./Home";

const token=getParameterByName('token');


// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

const Login = (props) => {
  { (token || sessionStorage.getItem("token") ? <Home /> : window.location.href = authUrl +  "/account/login?redirect=" + window.location.origin)}
  {!authUrl && document.write("Loading! Please wait...")}
};


export default Login;