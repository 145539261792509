import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { AD_URL, PAY_URL, STATIC_SERVER } from "../constants";
import axios from "axios";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BillingSer from "../services/BillingNew";


const Billing = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { userData } = useSelector(state => state.user);
  const [openInv, setOpenInv] = useState(false);
  const [invoicePath, setInvoicePath] = useState();
  const [invoiceData, setInvoiceData] = useState([]);
  const [invNum,setInvNum]=useState();
  const[payId,setPayId]=useState();
  //const [filterData, setFilterData] = useState();
   const getParameterById = (e, t = window.location.href) => {
    e = e.replace(/[\[\]]/g, "\\$&"); var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
    return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null;
  }

  const userId=getParameterById("id");
  const SUB_PATH = "api/checkout";

   useEffect(() => {
    if (!invoiceData.length) {
      axios.get(`${PAY_URL}/api/invoices/${userId?userId:currentUser.id}/ads`).then((res) => setInvoiceData(res.data));
      //getInvNumber();
    };
  },[]);

    if (!currentUser){
    return <Navigate to="/login" />;
  }

 //let  latestInv=invNum?invNum.split("_"):""
 //let invName=latestInv[1];
 //let invoiceNum=parseInt(latestInv[4])+1;
  // const getInvNumber=()=>{
  //    axios.get(`${PAY_URL}/api/invoice/latest`).then((res) => setInvNum(res.data));
  // }
//console.log(invName,invoiceNum);

let roles= currentUser.roles[0]==="ROLE_ADMIN"?true:false;
console.log(roles);
let filterUserInvData=[];
  if(invoiceData){
    filterUserInvData = invoiceData&&invoiceData.filter((invData)=>(invData.id===userId)||invData);
  }
 
  
  let filterUser = userData && userData.filter(d => d.id === userId);
  let userIfo = filterUser.length ? filterUser : '';

  const billPath = `${AD_URL}/invoices/index.html${userIfo.length ? `?userInfo=${userIfo[0].email}~${userIfo[0].username}&userId=${userId}&invoiceId=ADL200XX&currency=inr` : ''}`;
console.log(billPath);
  const createNewInvoice = (data) => {
    axios.put(PAY_URL + "/" + SUB_PATH , data) //https://pay.digitallive24.com/api/checkout
      .then(response =>{
        axios.get(`${PAY_URL}/api/invoices/${userId?userId:currentUser.id}`).then((res) => setInvoiceData(res.data));
      console.log( response.data)}).catch(error=>{
        console.log(error);
      });
  };
  const handleInvoice = (e) => {
    setInvoicePath(e.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
      let filterData=invoiceData&&invoiceData.filter((f)=>f._id===payId);
   
    let data = {
      payIntent:filterData&&filterData[0]&&filterData[0].payIntent ? filterData[0].payIntent:"",
      UserId: userId?userId:currentUser.id,    //currentUser.id
      AppName:filterData&&filterData[0]&&filterData[0].AppName?filterData[0].AppName:'ads',           //cms,ads,sok
      payType: filterData&&filterData[0]&&filterData[0].payType?filterData[0].payType:"ONETIME",       //subscription type onetime, subscription etc
      payStatus: filterData&&filterData[0]&&filterData[0].payType?filterData[0].payType:"UNPAID",      // paid, unpaid,failed
      invoicePath: invoicePath,
      receiptPath: "Receipt_" + invoicePath
           };
    //console.log(data)
    createNewInvoice(data);
    setInvoicePath("");

  }
  const handleAction = (id) => {
    console.log(id);
    let data=invoiceData&&invoiceData.filter((f)=>f._id===id);


    createNewInvoice({
      payIntent:data[0].payIntent ,
      UserId: userId,    //selected user
      AppName: data[0].AppName?data[0].AppName:'ads',           //cms,ads,sok
      payType: data[0].payType,       //subscription type onetime, subscription etc
      payStatus: "Paid",        // paid, unpaid,failed
      invoicePath: data[0].invoicePath,
      receiptPath: data[0].receiptPath  //`Receipt_ ${invoicePath}`
    })

  }
  const handlEdit=(id)=>{
      setPayId(id);
      let filterData=invoiceData&&invoiceData.filter((f)=>f._id===id);
      setInvoicePath(filterData[0].invoicePath);
      console.log(filterData);
  }

  const handlDel=(invId)=>{
      BillingSer.deleteInvoice(invId,currentUser.id);
  }

  return (
    <>

      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>Billing & Invoices</strong>
          </h3>
        </header>

        <p>
          <strong>Name :</strong> {filterUser.length ? filterUser[0].username : currentUser.username}
        </p>
        <p>
          <strong>Email :</strong> {filterUser.length ? filterUser[0].email : currentUser.email}
        </p>
        <strong>Payment :</strong>
        <ul className="mx-5">
          
        </ul>

      {roles && <div>
          {openInv ? <button style={{ float: 'right' }} className='closebtn btn btn-danger rounded-pill fw-bolder  ' onClick={() => setOpenInv(!openInv)}>close</button>
            : <button style={{ float: 'right' }} className='btn btn-primary rounded-pill fw-bolder' onClick={() => setOpenInv(!openInv)}>+Create New</button>}
        </div>}

        {roles&&<form >
          <div style={{ display: "none" }}>
            <label><strong>Upload Your File : </strong> </label>
            <input className="form-control w-25 p-1 btn btn-primary " type="file" name="file" />
          </div>

          <div>
            <label><strong>Invoice Path :</strong></label><br></br>
            <input type="text" onChange={handleInvoice} value={invoicePath} className="form-control" style={{ width: "400px" }}></input>
            <button type="submit" onClick={handleSubmit} className="btn btn-dark my-2 ">Update</button>
          </div>
        </form>}

       {!openInv &&<div className="tableContainer">
          <table className="table" rules="all" border="1" style={{ textAlign: "center", width: "100%" }}>
            <thead>
              <tr>
                <th scope="col">Date (D/M/YY)</th>
                <th scope="col">Payment status  </th>
                <th scope="col">Invoice</th>
                <th scope="col">Receipt</th>
                {roles&& <th>Action</th>}
              {roles?<th>Update</th>:<th>Pay here</th>}
              </tr>
            </thead>
            <tbody>
              {
                filterUserInvData.length && filterUserInvData.map((value, index) => {
                  let invPath=`${STATIC_SERVER}/${value.invoicePath}`;
                  let recPath=`${STATIC_SERVER}/${value.receiptPath}`;
                  let paymentStatus=value.payStatus&&value.payStatus.toUpperCase() === "PAID"?true:false;
                  return (
                    <>
                      <tr key={index}>
                        <td>{moment(`${value.updatedAt}`).format("D/M/YY")}</td>
                        <td>{paymentStatus ? <input type="checkbox" checked={value.payStatus.toUpperCase() === "PAID" ? true : false} style={{ height: "30px", width: "30px", color: "red",accentColor: "#10c710" }} /> : <span className="pending_chip">PENDING</span>}</td>
                        <td> <a href={invPath} target="_blank"  download>{value.invoicePath} </a> </td>
                        <td>{paymentStatus ?  <a href={recPath} target="_blank"  download>{value.receiptPath}</a>:""}</td>
                        {roles&&<td><button className="btn btn-success" onClick={() => handleAction(value._id)}>MARK PAID</button></td>}
                        {roles&& <td><button style={{border:"none",backgroundColor:"#fff"}} onClick={()=>handlEdit(value._id)}><EditIcon/></button></td>
                          }
                          {roles&& <td><button style={{border:"none",backgroundColor:"#fff",color:'red'}} onClick={()=>handlDel(value._id)}><DeleteIcon/></button></td>
                          }
                          { (!roles?value.payStatus.toUpperCase()==="UNPAID":false)?<td><button className="btn btn-success"><a className='text-light' target="_blank" style={{textDecoration:"none"}} href={invPath}  download>Pay now</a></button></td>:roles?"":<td></td>
                          }

                      </tr>
                    </>
                  )
                })
              }
            </tbody>
          </table>

        </div>}

      </div>
      <div>
        {openInv ? <iframe height='900px' width='100%' src={billPath} title="description"></iframe> : ""}
      </div>
    </>
  );
};

export default Billing;
