import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../constants";

const SUB_PATH = "/api/digi/ad-loc";

const getAdLocById = (id) => {
  return axios.get(API_URL+SUB_PATH+ "/"+id);
};

const createNewAdLoc = (data) => {
  return axios
    .post(API_URL + SUB_PATH, data,
    { headers: authHeader()
     })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
      }

      return response.data;
    });
};

const updateAdLoc = (data) => {
  return axios
    .put(API_URL + SUB_PATH +'/'+data.id, data,
    { headers: authHeader()
     })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
      }

      return response.data;
    });
};


export default {
	getAdLocById,
  	createNewAdLoc,
    updateAdLoc
};