import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../constants";

const SUB_PATH = "/api/digi/ad";

const getAdById = (id) => {
  return axios.get(API_URL + SUB_PATH + "/" + id);
};


const getAllAd = () => {
  return axios.get(API_URL + SUB_PATH + "/all", {
    headers: authHeader()
  });
}; 
console.log(API_URL + SUB_PATH + "/all")

const createNewAd = (data) => {
  return axios
    .post(API_URL + SUB_PATH, data,
      {
        headers: authHeader()
      })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
      }

      return response.data;
    });
};


export default {
  getAdById,
  getAllAd,
  createNewAd
};