export const BASE_URL = 'https://cms.digitallive24.com';
export const REPORTS_URL = 'https://reports.digitallive24.com';
export const API_URL = 'https://adapi.adslive.in';
export const LOC_GALLERY_URL = 'https://digisign24.s3.ap-south-1.amazonaws.com/location-gallery';
export const MEDIA_GALLERY = 'https://digisign24.s3.ap-south-1.amazonaws.com/users-media';
export const PAY_URL  = "https://pay.digitallive24.com";
export const AD_URL  = "https://digi.adslive.in";
export const STATIC_SERVER="https://digisign24.s3.ap-south-1.amazonaws.com/invoices";
export const authUrl = "https://auth.digitallive24.com";
export const authapi ="https://authapi.digitallive24.com";


export const getParameterByName = (e, t = window.location.href) => {
    e = e.replace(/[\[\]]/g, "\\$&");
    var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
    return n
      ? n[2]
        ? decodeURIComponent(n[2].replace(/\+/g, " "))
        : ""
      : null;
  };