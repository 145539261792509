import {
  MEDIA_SELECTION
} from "./types";


export const setMediaSelection = (data) => ({
  type: MEDIA_SELECTION,
  payload: data,
 
});


