import {
  SCREEN_LOC_LIST,
  SCREEN_LOC_SUCCESS,
  SCREEN_LOC_FAIL,
  SET_MESSAGE,
  SCREEN_LOC_SELECTION_UPDATE
} from "./types";

import ScreenLocService from "../services/screen_location.service";

export const getScreenLocationDetails = (id) => (dispatch) => {
  return ScreenLocService.getScreenDetails(id).then(
    (response) => {
      dispatch({
        type: SCREEN_LOC_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SCREEN_LOC_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const setScreenLocationSelection = (data) => ({
  type: SCREEN_LOC_SELECTION_UPDATE,
  payload: data,
  
});


export const setScreenLocationList = (data) => ({
  type: SCREEN_LOC_LIST,
  payload: data,
});

