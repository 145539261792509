import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../services/user.service";
import MediaService from "../services/media.service";
import { setUserData } from "../actions/user";
import { setMediaSelection } from "../actions/media";
import EventBus from "../common/EventBus";
import { Navigate, useParams, useNavigate, Link } from "react-router-dom";
import { MEDIA_GALLERY } from "../constants";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableRowsIcon from '@mui/icons-material/TableRows';
const BoardUser = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState("");
  const { userData } = useSelector((state) => state.user);
  const [fileToUpload, setFileToUpload] = useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();

    // console.log(currentUser);
  useEffect(() => {
    if (!userData || !userData.length) {
      (currentUser && currentUser.role.indexOf("ROLE_ADMIN") != -1
        ? UserService.getAdminBoard()
        : UserService.getUserBoard()
      ).then(
        (response) => {
          //setContent(response.data);
          dispatch(setUserData(response.data));
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setContent(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  }, []);

  const setFile = (e) => {
    setFileToUpload(e.target.files[0]);
  };

  const selectMedia = (media) => {
    dispatch(setMediaSelection(media));
    navigate("/publish-ad");
  };

  const uploadMedia = () => {
    const formData = new FormData();
    formData.append("files", fileToUpload);
    //console.log(fileToUpload);
    MediaService.uploadMedia(formData).then(
      (response) => {
        console.log(response.data);
        UserService.getUserBoard().then((response) => {
          dispatch(setUserData(response.data));
        });
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const renderMedias = () => {
    //https://digisign24.s3.ap-south-1.amazonaws.com/
    return userData.map((u) =>
      u.medias.map((p) => (
        <div
          key={p.id}
          className="col-sm-12 col-md-6 col-lg-4 my-3"
          style={{ width: "18rem", display: "inline-block" }}
        >
          <div className="card">
            <img
              className="userCard-img-top"
              src={
                MEDIA_GALLERY +
                "/" +
                (p.mediaPath.endsWith(".mp4")
                  ? p.mediaPath.replace(".mp4", ".png")
                  : p.mediaPath)
                
              }
              alt="Card_image cap"
            />
            <div className="card-body" style={{ textAlign: "left" }}>
              <h5 className="card-title">{p.mediaName}</h5>
              <p className="card-text">{p.mediaTags}</p>
              <label className="card-text">
                {"Uploaded By: " + u.username}
              </label>
              <span className="card-text" style={{ width: "100%" }}>
                {"Date: " +
                  (p.createdAt ? p.createdAt.split("T")[0] : "Not found")}
              </span>

            <div style={{display:'flex',justifyContent:'center' ,alignItems:'center'}}>
            <button
                onClick={() => selectMedia(p)}
                // style={{ float: "start" }}
                className="btn btn-primary"
              >
                Select To Publish
              </button>
            </div>
            </div>
          </div>
        </div>
      ))
    );
  };


 const listView=()=>{
     let list= document.getElementById('toggle-btn');
    list.style.display='flex';
   
    }
const gridView=()=>{
  let grid= document.getElementById('toggle-btn');
  grid.style.display='block';
}


  return !currentUser ? (
    <Navigate to="/login" />
  ) : (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{"Media"}</strong>
        </h3>
      </header>

      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h5>Upload New Ad</h5>
        <section>
          <form
            className="dropzone"
            encType="multipart/form-data"
            style={{ padding: "10px" }}
          >
            <input
              className="col-sm-12 col-md-6 btn btn-secondary"
              type="file"
              onChange={(e) => setFile(e)}
            />
            <input
              className="btn btn-success"
              type="button"
              value="Upload"
              onClick={() => uploadMedia()}
            />
          </form>
        </section>
      </div>

      <h5 style={{display:'flex',justifyContent:'space-between' }}>
        <strong>Select Media:</strong>
        <div>
        <button className='btn' onClick={gridView}><ViewModuleIcon/> </button>
         <button className='btn' onClick={listView}><TableRowsIcon/> </button>
        </div>
      </h5>
      <div className="detail-sec" id='toggle-btn' style={{ textAlign: "center" }}>
        {userData ? renderMedias() : content}
      </div>
    </div>
  );
};

export default BoardUser;
