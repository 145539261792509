import React, { useState, useEffect } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setScreenLocationSelection } from "../actions/screenAndLoc";
import ScreenLocService from "../services/screen_location.service";
import { LOC_GALLERY_URL } from "../constants";
import SimpleImageSlider from "react-simple-image-slider";
import * as geolib from "geolib";
import PlaceIcon from "@mui/icons-material/Place";
 import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
 import{BASE_URL}from "../constants";
 import { useNavigate } from "react-router-dom";


const ScreenAndLocationDetails = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState([]);
  const { locSelection } = useSelector((state) => state.screenLoc);
  const dispatch = useDispatch();
  // for location
  const [currentLocation, setCurrentLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [displayLink, setDisplayLink]= useState(false)
  const [editedNumberOfScreens, setEditedNumberOfScreens] = useState(content.noOfExtendedScreens);
  const [isEditMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const params = useParams();
  console.log(params);
  useEffect(() => {
    if(content.length===0){
      ScreenLocService.getScreenDetails(params.id).then(
        (response) => {
          console.log("Fetched Data:", response);
          let screenData = response.data;
          if (screenData.locationGallery) {
            screenData.locationGallery = screenData.locationGallery
              .split(",")
              .map((img) => LOC_GALLERY_URL + "/" + screenData.id + "/" + img);
          }
          console.log("screeen", screenData);
          setContent( (screenData instanceof Array && screenData.length)? screenData[0]:screenData);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
  
          setContent(_content);
        }
      );
    }
    
  }, [params.id]);
  const handleSave = () => {
    const updatedData = { ...content, noOfExtendedScreens: editedNumberOfScreens };
  
    ScreenLocService.editScreens(content.id ? content.id : content._id, updatedData)
      .then((response) => {
        navigate('/home');
      })
      .catch((error) => {
        console.error('Edit error', error);
      });
  
    setEditMode(false);
  };  
  const handleEditScreens = () => {
    setEditedNumberOfScreens(content.noOfExtendedScreens);
    setEditMode(true);
  };
  

  const updateLocSelection = (locId) => {
    if (!locId) return;
    let locUpdate = JSON.parse(JSON.stringify(locSelection));
    if (locId && locSelection.indexOf(locId) == -1) {
      locUpdate.push(locId);
    } else if (locId && locSelection.indexOf(locId) != -1) {
      console.log("dd");
      let locIdIndx = locSelection.indexOf(locId);
      locUpdate.splice(locIdIndx, 1);
    }
    dispatch(setScreenLocationSelection(locUpdate));
  };


  const getDistance = (lat, lng)=>{
    console.log("latitude",lat)
    let distanceInKm=1.2;
    if (currentLocation&& lat && lng ) {
      let screenLocaiton= {
        latitude:lat,
        longitude:lng
      };
       distanceInKm = geolib.getDistance(currentLocation,screenLocaiton,1, 1) / 1000;
       console.log(distanceInKm);
  }
  return distanceInKm.toFixed(2);
}

  useEffect(() => {
    if(!currentLocation){
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
      },
      (error) => {
        console.log(`Error retriving current location: ${error.message}`);
      }
    );
    }
  });
  
  const getUrl=()=>{
    setDisplayLink(true)
  }
  const locaiton= `
  <a href="https://www.google.com/maps/place/MSR+Mansion/@13.0686064,77.6195553,18.5z/data=!4m14!1m7!3m6!1s0x3bae19c87f110d5d:0x4a34a16c9411045b!2sMSR+Mansion!8m2!3d13.0685089!4d77.620128!16s%2Fg%2F11gmysydtt!3m5!1s0x3bae19c87f110d5d:0x4a34a16c9411045b!8m2!3d13.0685089!4d77.620128!16s%2Fg%2F11gmysydtt!5m1!1e4
  ">Click</a>
  `
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return <div>...Loading</div>;
  } 


  return !currentUser ? (
    <Navigate to="/login" />
  ) : (
    <div className="container">
    
      <header className="jumbotron">
        <h3>
          <strong>{content.playerName}</strong> Details
        </h3>
      </header>
      <div
        style={{
          textAlign: "center",
          marginLeft: "20px",
          marginBottom: "10px",
        }}
      >
        {content && content.locationGallery && (
          <SimpleImageSlider
            width={300}
            height={400}
            images={content.locationGallery}
            showBullets={true}
            showNavs={true}
          />
        )}
      </div>
      <div className="detail-sec">
        <h5>
          <strong>Address & Locality:</strong>
        </h5>
        <label>{content.address}</label>
        <span>{content.description}</span>
        <span>{content.locationPinCode}</span>
      </div>

      <div className="detail-sec">
        <h5>
          <strong>Screens details:</strong>
        </h5>
        {/* <span>
          {
            "Your ad will be placed at defined area on screen. Template running is like below snapshot."
          }
        </span> */}
        {/* <div style={{ width: "350px", height: "400px" }}>
          <img
            style={{
              marginTop: "26%",
              transform: "rotate(" + content.rotateAngle + "deg)",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            className="img-thumbnail"
            src="https://cms.digitallive24.com/preview/pop_shots/63333451939852b126c8b1d2.jpg"
          />
        </div> */}
        {/* <label>{"Power Status"}</label>
        <span className="blink_txt highlight_grn">{"ONLINE"}</span> */}

        <label>{"Orientation"}</label>
        <span>{content.orientation}</span>

        <label>{"Power On & Off Schedule"}</label>
        <span>{content.OnOffTimings}</span>
        <label>{"Number of Flats"}</label>
        <span>{content.ip}</span>

        {
  isEditMode ? (
    <div>
      <input
        type="number"
        value={editedNumberOfScreens}
        onChange={(e) => setEditedNumberOfScreens(e.target.value)}
      />
      <button onClick={handleSave}>Save</button>
    </div>
  ) : (
    <div>
      <label>{"Number of screens"}</label>
      <span>{content.noOfExtendedScreens}</span>
    </div>
  )
}
      </div>
     
      {/* <div className="d-flex ">
        <PlaceIcon onClick={getUrl}  />
        <p className="badge text-wrap text-dark">{getDistance(content.latitude, content.longitude)} KM</p> <br/>
        {
          displayLink && <iframe
          srcDoc={locaiton}
          title="Map Link"/>
        }
      </div> */}

      <div className="detail-sec">
        <Link className="btn btn-info" to={"/home"}>
          Back
        </Link>
        {currentUser && currentUser.roles.includes("ROLE_ADMIN") && (
          <>
        <button
          onClick={() => {
            ScreenLocService.deleteScreens(content.id?content.id:content._id);
            navigate("/home");
          }}
          className="btn btn-danger m-2"
        >
          Delete
        </button>
        <button
        onClick={() => {
          ScreenLocService.editScreens(content.id?content.id:content._id);
          // navigate("/home");
        }}
        className="btn btn-info m-2"
      >
        Edit
      </button>
      </>
        )}
        {/* {locSelection.indexOf(content.id) == -1 ? (
          <button
            onClick={() => updateLocSelection(content.id?content.id:content._id)}
            style={{ float: "right" }}
            className="btn btn-primary"
          >
            Add To Publish
          </button>
        ) : (
          <span style={{ float: "right", marginTop: "7px" }}>
            <span className="right-tick"></span>
            {" WishList"}
          </span>
        )} */}
        
      </div>
    </div>
  );
};

export default ScreenAndLocationDetails;
