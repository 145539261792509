import React, { useState, useEffect } from 'react';
import ScreenLocService from "../services/screen_location.service";
import { Dialog, DialogContent, DialogTitle, TextField, Button, Grid } from '@mui/material';

const EditPopup = ({ open, handleClose, screenId }) => {
  const [editedData, seteditedData] = useState({
    ip: '',
    latitude: '',
    address: '',
    longitude: '',
    noOfExtendedScreens: '',
    locationPinCode: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    seteditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditSubmit = async () => {
    try {
      await ScreenLocService.editScreens(screenId, editedData)

        .then((response) => {
          console.log("Edit success", response);
          window.location.reload();
        });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await ScreenLocService.getScreenDetails(screenId);
        console.log('Response data:', response.data);
        const screenData = response.data[0];
        seteditedData(screenData);
      } catch (error) {
        console.error(error);
      }
    };

    if (open && screenId) {
      fetchData();
    }
  }, [open, screenId]);
  console.log('editedData:', editedData);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Player Details</DialogTitle>
      <DialogContent style={{paddingTop:'10px'}}>
        <form onSubmit={handleEditSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="ip"
                label="#Flat"
                fullWidth
                value={editedData.ip || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="latitude"
                label="Latitude"
                fullWidth
                value={editedData.latitude || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="address"
                label="Address"
                fullWidth
                value={editedData.address || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="longitude"
                label="Longitude"
                fullWidth
                value={editedData.longitude || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="noOfExtendedScreens"
                label="#Screens"
                fullWidth
                value={editedData.noOfExtendedScreens || ''}
                onChange={handleInputChange}
              />
            </Grid>

             <Grid item xs={12}>
              <TextField
                name="locationPinCode"
                label="Pin Code"
                fullWidth
                value={editedData.locationPinCode || ''}
                onChange={handleInputChange}
              />
            </Grid>

          </Grid>
          <Button variant="contained" onClick={handleEditSubmit} className="ml-2 mt-2">
            Save Changes
          </Button>
          <Button variant="contained" onClick={handleClose} className="ml-2 mt-2">
            Close
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditPopup;
