import React from "react";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
console.log(currentUser);
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
    
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.username}</strong> Profile
        </h3>
      </header>
      {false && <p>
        <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
        {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
      </p>}
      {false && <p>
        <strong>Id:</strong> {currentUser.id}
      </p>}
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>
      <p>
     <strong>Add image  </strong>
<image id="profileImage" src="http://lorempixel.com/100/100" />
<input id="imageUpload" type="file" 
       name="profile_photo" placeholder="Photo" required="" capture></input></p>

     <p><strong>Add phone number</strong>
      <input type="tel" name="number"></input></p>

      <p><strong>Signup date</strong>
      
      </p>
    
    </div>
  );
};

export default Profile;
