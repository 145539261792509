import { Button, Grid, TextField, Dialog, DialogContent,DialogActions, DialogTitle, MenuItem ,IconButton} from '@mui/material';
import { useState } from 'react';
import ScreenLocService from "../services/screen_location.service";
import CloseIcon from '@mui/icons-material/Close';

const cityOptions = [
    {
        value: 'Bangalore',
        label: 'Bangalore'
    },
    {
        value: 'Chennai',
        label: 'Chennai'
    },
    {
        value: 'Hyderabad',
        label: 'Hyderabad'
    },
    {
        value: 'Mumbai',
        label: 'Mumbai'
    }
];
const providerOptions = [
    {
        value: 'DL24',
        label: 'DL24'
    },
    {
        value: 'AOM',
        label: 'AOM'
    },
    {
        value: 'BP',
        label: 'BP'
    },
    {
        value: 'FLKT',
        label: 'FLKT'
    }
];
const AddScreen = ({ handleClose, open }) => {
    const [screenDetails, setScreenDetails] = useState({
        MAC: "",
        OnOffTimings: "",
        address: "",
        appId: "",
        contentType: "",
        description: "",
        groupId: "",
        ip: "",
        isExtended: true,
        isPublic: true,
        latitude: "",
        locationGallery: "",
        locationPinCode: "",
        longitude: "",
        noOfExtendedScreens: "",
        orientation: "",
        playerName: "",
        player_ver_installed: "",
        powerStatus: "",
        pushPlayerId: "",
        rotateAngle: "",
        screenNumber: 0,
        screenshot_ago: "",
        snapshot_taken_at: 0,
        status: "",
        thumb: "",
        userId: "12345",
        cityName: "",
        provider: ""
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setScreenDetails({
            ...screenDetails,
            [name]: value,
        });
    };

    const handleAddScreen = () => {
        ScreenLocService.addScreens(screenDetails)
            .then((response) => {
                console.log("screen", response);
                handleClose();
                window.location.reload();
            })
            .catch((error) => {
                console.error('Add Screen Error:', error);
            });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="customized-dialog-title">
                <DialogTitle>Add Location</DialogTitle>
                 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="City Name"
                                size="small"
                                name="cityName"
                                value={screenDetails.cityName}
                                onChange={handleInputChange}
                                sx={{ width: '65%' }}
                            >
                                {cityOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Provider"
                                size="small"
                                name="provider"
                                value={screenDetails.provider}
                                onChange={handleInputChange}
                                sx={{ width: '65%' }}
                            >
                                {providerOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Location Name"
                                size="small"
                                name="playerName"
                                value={screenDetails.playerName}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                size="small"
                                name="address"
                                value={screenDetails.address}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="#Screens"
                                size="small"
                                name="noOfExtendedScreens"
                                value={screenDetails.noOfExtendedScreens}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="#Flats"
                                name="ip"
                                size="small"
                                value={screenDetails.ip}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Latitude"
                                size="small"
                                name="latitude"
                                value={screenDetails.latitude}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Longitude"
                                size="small"
                                name="longitude"
                                value={screenDetails.longitude}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="PIN code"
                                size="small"
                                name="locationPinCode"
                                value={screenDetails.locationPinCode}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        
                    </Grid>

                </DialogContent>
                <DialogActions>
     
                        <Button variant="contained" onClick={handleAddScreen} className="ml-4 mt-2">
                            Save
                        </Button>
                       
        </DialogActions>
                
            </Dialog>
        </>
    );
};

export default AddScreen;
