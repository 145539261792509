import React from "react";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const FAQ = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>FAQ</strong>
        </h3>
      </header>
      
    
        <div><strong>How it works?</strong></div>
      
      <span>Submit your Ads and select your choice of locations where our screens are playing. Then it will played on those locations.</span>

       <br/>
        <div style={{marginTop:'10px'}}><strong>How many times my Ad will be displayed?</strong></div>      
     <span>It will be displayed twice in every hour.</span>
      
     <br/>
        <div style={{marginTop:'10px'}}><strong>How many screens will be there in a location?</strong></div>
      
     <span>It depends on location to location. Every location will have this detail and numbers added.</span>

    </div>
  );
};

export default FAQ;
