import {
  USER_DATA_SUCCESS
} from "../actions/types";

const initialState = {userData:[]}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_DATA_SUCCESS:
      return {
        ...state,
        userData: payload
      };
    default:
      return state;
  }
}
