import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../services/user.service";
import AdService from "../services/ad.service";
import Billing from "../services/BillingNew";
import AdLocService from "../services/ad-loc.service";
import EventBus from "../common/EventBus";
import { Navigate, useParams, Link, useNavigate } from 'react-router-dom';
import { MEDIA_GALLERY } from "../constants";
import toast from 'react-hot-toast';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';

import DownloadIcon from '@mui/icons-material/Download';

const PublishAd = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState("");
  const [apartList,setApartList]=useState([]);
  const { locSelection, locList } = useSelector(state => state.screenLoc);
  const { mediaSelected } = useSelector(state => state.media);
  const notify = (msg) => toast.success(msg);
  console.log(mediaSelected);
  console.log(locList);
  let navigate = useNavigate();

  useEffect(() => {

    if (!locSelection || !locSelection.length) {
      navigate("/home");
    }

  }, []);

useEffect(()=>{
      UserService.getPartnersContent()
      .then(res=>{
        res.data&& setApartList(res.data);
        
      });
      if(locList && locList.length){
  locList.map(li=>setApartList(prev=>[...prev,li]))
}
  },[])



  const saveAdForApproval = () => {
    let adDetails = {
      campaignName: mediaSelected.mediaName,
      mediaRefPaths: mediaSelected.mediaPath,
      isMediaReference: 'FALSE',
      mediaId: mediaSelected.id,
      playForDuration: document.getElementById('frequency').value,
      repeatDuration: document.getElementById('repeat').value
    };
    let addBillingDetails = {

      payIntent: "",
      userId: "",
      AppName: "",
      payStatus: "",
      invoicePath: "",
      receiptPath: ""
    }
    Billing.createNewInvoice(addBillingDetails)
    AdService.createNewAd(adDetails).then(
      (response) => {
        console.log(response);
        let adLocDetails = {
          adId: response.id,
          playerIds: locSelection,
          approvalStatus: false
        };
        AdLocService.createNewAdLoc(adLocDetails);
        notify("Submitted for approval!!")
        navigate("/");
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

  }

  const handlePdf = ()=>{
    var element = document.getElementById('pdf');
var opt = {
  margin:       1,
  filename:     'Agreement.pdf',
  image:        { type: 'png', quality: 0.95 },
  html2canvas:  { scale: 1 },
  jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
};
 
// New Promise-based usage:
html2pdf().from(element).set(opt).save();
  }


  const renderLocations = () => {
    //https://digisign24.s3.ap-south-1.amazonaws.com/
    return apartList.filter((p) => locSelection.indexOf(p.id?p.id:p._id) !== -1).map(p =>{ 
      const result = [];
          for (let i = 1; i <=p.noOfExtendedScreens;i++) {
            result.push(<option value={i}>{i}</option>);
          }
              
    return (<li key={'loc' + p.id} style={{width:"250px",display:"flex",justifyContent:"space-between",margin:"5px"}}>{p.playerName}
           <span >
           <select defaultValue={p.noOfExtendedScreens} style={{textAlign:"center",border:"none",outline:"none"}} >
                {result}   
          </select>
          </span>
      </li>)
    });

  };

  return (
    !currentUser ?
      <Navigate to="/login" />
      :
      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>{'Review & Publish'}</strong>
          </h3>
          <button className='btn btn-danger' onClick={handlePdf}><DownloadIcon/>PDF</button>
        </header>
       
        <div className="detail-sec" >
          <h5 ><strong>Selected Ad:</strong></h5>
          <img style={{ marginTop: '10px' }} className="card-img-top col-md-6 col-sm-12" src={MEDIA_GALLERY + '/' + (mediaSelected.mediaPath.endsWith('.mp4') ? mediaSelected.mediaPath.replace('.mp4', '.png') : mediaSelected.mediaPath)} alt="" />
        </div>
         <div id="pdf">
            <div className="detail-sec">
              <label>{'Media Name:'}</label>
              <span>{mediaSelected.mediaName}</span>
              <h5 style={{ marginTop: '15px' }} ><strong>Selected Locations & No.of screens:</strong></h5>
              <div>{renderLocations()}</div>
            </div>

        <div className="detail-sec">
          <h5 ><strong>Display Frequency:</strong></h5>
          <div><select id="frequency" className="form-select col-sm-12 col-md-6">
            <option>DAYS</option>
            <option>WEEKS</option>
            <option>MONTHS</option>
          </select></div>
          <label>Repeat For</label> 
          <div><select id="repeat" className="form-select col-sm-12 col-md-6">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </select></div>
        </div>

    </div>
        <div className="detail-sec" style={{ bottom: '0px', position: 'fixed', width: '100%', background: '#fff', borderTop: '1px solid #f1f1f1', left: '0px', padding: '10px' }}>
          <Link className="btn btn-info" to={"/user"}>Back</Link>
          <button style={{ float: 'right', fontSize: '1em' }} onClick={() => saveAdForApproval()} className="btn btn-success">PUBLISH NOW!</button>
        </div>
      </div>

  );
};

export default PublishAd;
