import {
  SCREEN_LOC_LIST,
  SCREEN_LOC_SELECTION_UPDATE
} from "../actions/types";

const initialState = {locSelection:[], locList:[]}

export default function (state = initialState, action) {
  const { type, payload } = action;
  console.log(payload);
  switch (type) {
    case SCREEN_LOC_SELECTION_UPDATE:
      return {
        ...state,
        locSelection: payload
      };
      case SCREEN_LOC_LIST:
      return {
        ...state,
        locList: payload
      };
    default:
      return state;
  }
}
