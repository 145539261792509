import axios from "axios";
import authHeader from "./auth-header";
import { PAY_URL } from "../constants";

const SUB_PATH = "/api/checkout";

const getAdById = (id) => {
    return axios.get(PAY_URL + SUB_PATH + "/" + id);
};

const getAllAd = () => {
    return axios.get(PAY_URL + SUB_PATH + "/all", {
        headers: authHeader()
    });
};

const createNewInvoice = (data) => {
    return axios
        .post(PAY_URL + SUB_PATH, data)
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }

            return response.data;
        });
};

const deleteInvoice = (invId,userId) => {
    return axios.delete(PAY_URL + "/api/invoices/" + userId+"/"+invId) //https://pay.digitallive24.com/api/checkout
      .then(response =>{
        axios.get(`${PAY_URL}/api/invoices/${userId}/ads`).then((res) => //setInvoiceData(res.data));
      console.log( response.data)).catch(error=>{
        console.log(error);
      });
  });
}


export default {
    getAdById,
    getAllAd,
    createNewInvoice,
    deleteInvoice
};