import React from "react";
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const AboutUs = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>About Us:</strong>
        </h3>
      </header>
      <label>It is powered by <b>Digital Live 24</b>. A Bangalore based start up in Digital signage platform. We help you to grow with our digital presence in your surronding locations.</label>

      <p>
        <strong>Contact us : </strong> 
        {' info@digitallive24.com'}<br/>
        <strong>Mobile/What's App : </strong> {'7019910793'}<br/>
        <strong>Visit us : </strong> {'https://digitallive24.com'}
      </p>
      
    </div>
  );
};

export default AboutUs;
