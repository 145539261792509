import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {getParameterByName, authapi} from "./constants"
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import PublishAd from "./components/PublishAd";
import Ads from "./components/Ads";
import Billing from "./components/Billing";
import Reports from "./components/Reports";
import FAQ from "./components/FAQ";
import AboutUs from "./components/AboutUs";
import ScreenAndLocationDetails from "./components/ScreenAndLocationDetails";
import Footer from './Footer';
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { Toaster } from 'react-hot-toast';
// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./actions/types";

const App = () => {
  
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const [userMenuOpen, setUserMenuOpen]= useState(false);
  const dispatch = useDispatch();
  let location = useLocation();

  const token=getParameterByName("token");
  console.log(token);

  let userToken =token?token:sessionStorage.getItem("token")?sessionStorage.getItem("token"):"";
    const imuserDetails =null;
   let tokenUrl= authapi+"/user/validate-token";
console.log(userToken);
useEffect(() => {
  if (userToken) {
    axios({
      method: 'post',
      url: tokenUrl,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    })
    .then(res => {
      sessionStorage.setItem("token", userToken);
      sessionStorage.setItem("userData", JSON.stringify(res.data.user));
      localStorage.setItem("user", JSON.stringify(res.data.user));
      if(getParameterByName("token")){
        window.location.href=  window.location.origin
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: res.data.user },
      });
    })
    .catch(error => {
      console.error("Token validation failed:", error);
      console.log("sans", error);
      // Handle unauthorized (invalid/expired token) here or redirect to login
    });
  }
}, [userToken]);

    useEffect(() => {
      if (["/login", "/register"].includes(location.pathname)) {
        dispatch(clearMessage()); // clear message when changing location
      }
    }, [dispatch, location]);
  
    const logOut = useCallback(() => {
      dispatch(logout());
    }, [dispatch]);
  useEffect(() => {
    console.log(currentUser);
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles =="ADMIN" );
      setShowAdminBoard(currentUser.roles == "SUPER-ADMIN");
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          <span className="logo-top"></span>
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Locations
            </Link>
          </li>
          

          {showModeratorBoard && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Clients
              </Link>
            </li>
          )}

          {false && currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                Media
              </Link>

            </li>
            )}
          {false &&currentUser && <li className="nav-item">
              <Link to={"/ads"} className="nav-link">
                Ads
              </Link>
            </li>}
        </div>

        {currentUser ? (
          <div className="btn-group">

  <button style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}} type="button"  onClick={()=> setUserMenuOpen(!userMenuOpen)}>
    <label style={{cursor:'pointer'}}><AccountCircleSharpIcon fontSize="large" color="#fff" />
    <span  style={{color:"#fff"}}> {currentUser.name}</span>
   </label>
  </button>
    
      <MenuList className={'popup-tool'} style={{display:userMenuOpen?"block":"none"}}>
        <MenuItem onClick={()=> setUserMenuOpen(!userMenuOpen)}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Link to={"/profile"} className="nav-link">
                {currentUser.name}
              </Link></ListItemText>
          
        </MenuItem>
        <MenuItem onClick={()=> setUserMenuOpen(!userMenuOpen)}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Link to={"/billing"} className="nav-link">
                {'Billing'}
              </Link></ListItemText>
          
        </MenuItem>
        <MenuItem onClick={()=> setUserMenuOpen(!userMenuOpen)}>
          <ListItemIcon>
            <PieChartOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> <Link to={"/report"} className="nav-link">
                {'Reports'}
              </Link></ListItemText>
         
        </MenuItem>
        <MenuItem onClick={()=> setUserMenuOpen(!userMenuOpen)}>
          <ListItemIcon>
            <HelpCenterIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Link to={"/faq"} className="nav-link">
                {'FAQs'}
              </Link></ListItemText>
        </MenuItem>
         <Divider />
        <MenuItem onClick={()=> setUserMenuOpen(!userMenuOpen)}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> <Link href="/login" className="nav-link" onClick={logOut}>
                LogOut
              </Link></ListItemText>
        </MenuItem>
      </MenuList>
</div>

        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            {false&&<li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>}
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/locations-details/:id" element={<ScreenAndLocationDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/publish-ad" element={<PublishAd />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/user" element={<BoardUser />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/billing?id=:id" element={<Billing />} />
           <Route path="/report" element={<Reports />} />
          <Route path="/report?id=:id" element={<Reports/>} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </div>
     {currentUser && currentUser.role && <Footer />}
      <Toaster />
      {/* <AuthVerify logOut={logOut}/> */}
    </div>
  );
};

export default App;
